import { Label } from '@radix-ui/react-label';
import React, { useState } from 'react';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { emptySignUpErrors, emptySignUpObj, SignUpDataObj, SignUpErrors } from '../../../interfaces/AccountDataTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { TextInput } from '../../BasicForm/FormTextInput/FormTextInput';
import { ToggleVisibility } from '../../BasicUtil/ToggleVisibility/ToggleVisibility';
import { BasicAuthBase, PasswordRequirements } from '../SharedAccount';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { usePostSignUpData } from '../../../api/usePostSignUpData';
import { passwordValidationMessages, signUpSchema } from '../../../configBuilder/devYupSchemas';

interface ISignUpCardProps {
    setSuccessMessage: (successMessage: string) => void;
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
}

export const SignUpCard = (props: ISignUpCardProps) => {
    const { setSuccessMessage, iuCustomStyle, iuVariant, ...otherProps } = props;
    const [signUpValues, setSignUpValues] = useState<SignUpDataObj>(emptySignUpObj);
    const [yupErrors, setYupErrors] = useState<SignUpErrors>(emptySignUpErrors);

    const postSignUpData = usePostSignUpData(setSuccessMessage, signUpValues);

    const classNames = {
        SignUpCard__passwordContainer: 'SignUpCard__passwordContainer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <BasicAuthBase
            accountValues={signUpValues}
            setAccountValues={setSignUpValues}
            emptyAccountErrors={emptySignUpErrors}
            yupSchema={signUpSchema}
            yupErrors={yupErrors}
            setYupErrors={setYupErrors}
            mutationHandler={postSignUpData}
            setSuccessMessage={setSuccessMessage}
            iuCustomStyle={iuCustomStyle}
            {...otherProps}
        >
            <div className={newClassNames.SignUpCard__passwordContainer}>
                <TextInput<SignUpDataObj>
                    iuLabel="Email Address"
                    setValues={setSignUpValues}
                    yupValueKey="username"
                    value={signUpValues.username}
                    yupError={yupErrors.username}
                    iuCustomStyle={iuCustomStyle}
                    iuVariant={iuVariant}
                    iuAutoComplete="username"
                />
                <Label id="password">Password</Label>
                <ToggleVisibility<SignUpDataObj>
                    yupValueKey="password"
                    value={signUpValues.password}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setSignUpValues(prevValue => ({ ...prevValue, password: event.target.value }))}
                    iuCustomStyle={iuCustomStyle}
                    iuVariant={iuVariant}
                    iuAutoComplete="new-password"
                    yupError={[]}
                />
            </div>
            <PasswordRequirements
                yupErrors={yupErrors}
                signUpValues={signUpValues}
                passwordValidationMessages={passwordValidationMessages}
                iuCustomStyle={iuCustomStyle}
            />
        </BasicAuthBase>
    );
};
