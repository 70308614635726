import { useMemo } from 'react';
import { getCustomDropin } from '../../../configBuilder/configUtil';

interface ICustomDropinProps {
    dropinKey: string;
}

const CustomDropin = (props: ICustomDropinProps) => {
    const { dropinKey } = props;

    const dropinContent = useMemo(() => getCustomDropin(dropinKey), [dropinKey]);
    return <>{dropinContent}</>;
};

export default CustomDropin;
