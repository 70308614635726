import './FormSelectGroup.scss';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { Label } from '@radix-ui/react-label';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { SingleSelect } from './FormSelect';

interface IFormSelectGroupProps {
    iuLabel: string;
    yupValueKeys: Array<keyof StudentAnswersObj>;
    iuSideOffset?: number;
    dropdownOption?: string;
    iuCustomStyle?: ICustomStyle[];
}
export const FormSelectGroup = (props: IFormSelectGroupProps) => {
    const { iuLabel, yupValueKeys, iuCustomStyle, iuSideOffset } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();

    const classNames = {
        SelectGroup: 'SelectGroup',
        SelectGroup__label: 'SelectGroup__label',
        SelectGroup__answers: 'SelectGroup__answers',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    const ariaLabelText = yupValueKeys[0].toString();

    return (
        <div className={newClassNames.SelectGroup}>
            <Label className={newClassNames.SelectGroup__label} id={ariaLabelText}>
                {iuLabel}
            </Label>
            <div className={newClassNames.SelectGroup__answers}>
                {yupValueKeys.map(valueKey => {
                    return (
                        <SingleSelect
                            yupValueKey={valueKey}
                            setValues={setStudentAnswers}
                            value={studentAnswers[valueKey]}
                            iuCustomStyle={iuCustomStyle}
                            key={valueKey.toString()}
                            iuSideOffset={iuSideOffset}
                            aria-labelledby={ariaLabelText}
                        />
                    );
                })}
            </div>
        </div>
    );
};
