import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { PasswordResetObj } from '../interfaces/AccountDataTypes';

export const usePostForgotPassword = (setSuccessMessage: (successMessage: string) => void) => {
    return useMutation((values: PasswordResetObj) => statusHandler(fetch, '/public/forgot-password', 'POST', values), {
        ...defaultReactQueryOptions,
        onSuccess: (apiResp: { message: string }) => {
            setSuccessMessage(apiResp.message);
        },
        onError: () => {},
    });
};
