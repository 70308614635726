import { useState } from 'react';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import FormStyledInput from '../../BasicForm/FormStyledInput/FormStyledInput';
import ValidationError from '../../BasicForm/FormValidationError/FormValidationError';
import MaterialIcon from '../MaterialIcon';
import './ToggleVisibility.scss';
import { primitives } from '../../../interfaces/StudentProfileTypes';

interface IToggleVisibility<T extends SupportedComplexObjs> {
    yupValueKey: keyof T;
    value?: primitives;
    handleChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
    iuAutoComplete?: string;
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
    yupError: string[];
}

export const ToggleVisibility = <T extends SupportedComplexObjs>(props: IToggleVisibility<T>) => {
    const { yupValueKey, handleChange, iuCustomStyle, value, yupError, ...otherProps } = props;
    const valueKey = yupValueKey.toString();

    const [isVisible, setIsVisible] = useState(false);

    const classNames = {
        ToggleVisibility__inlineInput: 'ToggleVisibility__inlineInput',
        ToggleVisibility__iconButton: 'ToggleVisibility__iconButton',
        ToggleVisibility__icon: 'ToggleVisibility__icon',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <>
            <div className={newClassNames.ToggleVisibility__inlineInput}>
                <FormStyledInput
                    aria-labelledby={valueKey}
                    onChange={handleChange}
                    aria-invalid={yupError.length > 0}
                    inputType={isVisible ? 'text' : 'password'}
                    aria-describedby={`${valueKey}-error`}
                    value={value}
                    {...otherProps}
                />
                {/* FIXME spans are not keyboard focusable even with button role */}
                <span role="button" data-testid={`${valueKey}-toggle`} className={newClassNames.ToggleVisibility__iconButton} onClick={() => setIsVisible(!isVisible)}>
                    <MaterialIcon className={newClassNames.ToggleVisibility__icon} iconName={isVisible ? 'visibility' : 'visibility_off'} iuCustomStyle={iuCustomStyle} />
                </span>
            </div>
            <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
        </>
    );
};
