import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { evaluateConditionals, useClearOnHide } from '../../../configBuilder/configUtil';
import { IConditionObj } from '../../../interfaces/ConditionTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';

interface IHideShowSectionProps {
    children?: JSX.Element[] | JSX.Element;
    iuIsHidden: IConditionObj<StudentAnswersObj>;
    iuAnswersToClear?: Array<keyof StudentAnswersObj>;
}

const HideShowSection = (props: IHideShowSectionProps) => {
    const { children, iuIsHidden, iuAnswersToClear = [] } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const isHidden = evaluateConditionals(iuIsHidden, studentAnswers);
    const valueToWatch = studentAnswers[iuIsHidden.valueKey];

    useClearOnHide({
        setStudentAnswers,
        valueToWatch,
        answerKeysToClear: iuAnswersToClear,
    });

    return <div>{isHidden ? undefined : children}</div>;
};

export default HideShowSection;
