import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@radix-ui/react-accordion';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';

interface IBasicAccordionProps {
    defaultValue?: string;
    collapsible?: boolean;
    children: JSX.Element | JSX.Element[];
}

export const BasicAccordion = (props: IBasicAccordionProps) => {
    const { children, ...otherProps } = props;
    return (
        <Accordion type="single" {...otherProps}>
            {children}
        </Accordion>
    );
};

interface IBasicAccordionItemProps {
    header: string;
    children: JSX.Element | JSX.Element[];
    value: string;
    iuCustomStyle?: ICustomStyle[];
}

export const BasicAccordionItem = (props: IBasicAccordionItemProps) => {
    const { header, children, value, iuCustomStyle } = props;

    const classNames = {
        CompletionPage__accordionHeader: 'CompletionPage__accordionHeader',
        CompletionPage__content: 'CompletionPage__content',
        CompletionPage__completeIcon: 'CompletionPage__completeIcon',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <AccordionItem value={value}>
            <AccordionTrigger className={newClassNames.CompletionPage__accordionHeader}>
                <img className={newClassNames.CompletionPage__completeIcon} src="/oru-logo.png" alt="Oral Roberts Eagle Logo" />
                {header}
            </AccordionTrigger>
            <AccordionContent className={newClassNames.CompletionPage__content}>{children}</AccordionContent>
        </AccordionItem>
    );
};
