import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { LoginDataObj } from '../interfaces/AccountDataTypes';
import { useNavigate } from 'react-router-dom';
import { useClientSnapshot } from '../stateManagement/useClientSnapshot';

export const usePostLoginData = (setSuccessMessage: (successMessage: string) => void) => {
    const navigate = useNavigate();
    const { setClientSnapshot } = useClientSnapshot();

    return useMutation((values: LoginDataObj) => statusHandler(fetch, '/public/login', 'POST', values), {
        ...defaultReactQueryOptions,
        onSuccess: (apiResp: { message: string }) => {
            setSuccessMessage(apiResp.message);
            setClientSnapshot(prevValue => ({ ...prevValue, is_logged_in: true }));
            navigate('/');
        },
        onError: () => {},
    });
};
