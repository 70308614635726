import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import ValidationError from '../FormValidationError/FormValidationError';
import { Label } from '@radix-ui/react-label';

import './FormTextArea.scss';
import React, { useState } from 'react';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import { primitives } from '../../../interfaces/StudentProfileTypes';

const DEFAULT_CHARACTER_LIMIT = 1_500;

interface ITextAreaProps<T extends SupportedComplexObjs> {
    iuCharacterLimit?: number;
    yupValueKey: keyof T;
    value: primitives;
    setValues: (value: React.SetStateAction<T>) => void;
    yupError: string[];
    children: JSX.Element | JSX.Element[];
    iuCustomStyle?: ICustomStyle[];
    iuRows?: number;
}

export const TextArea = <T extends SupportedComplexObjs>(props: ITextAreaProps<T>) => {
    const { iuCharacterLimit = DEFAULT_CHARACTER_LIMIT, value, setValues, yupError, children, yupValueKey, iuCustomStyle, iuRows = 6 } = props;
    const [localState, setLocalState] = useState<primitives>(value);
    const valueKey = yupValueKey.toString();

    const classNames = {
        TextArea: 'TextArea',
        TextArea__blurb: 'TextArea__blurb',
        TextArea__description: 'TextArea__description',
        TextArea__input: 'TextArea__input',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.TextArea}>
            <Label id={valueKey} className={newClassNames.TextArea__blurb}>
                {children}
            </Label>
            <textarea
                className={newClassNames.TextArea__input}
                aria-labelledby={valueKey}
                value={localState?.toString()}
                name={valueKey}
                rows={iuRows}
                aria-invalid={yupError.length > 0}
                aria-describedby={`${valueKey}-error`}
                onChange={event => setLocalState(event.target.value)}
                onBlur={() => setValues(prevValue => ({ ...prevValue, [yupValueKey]: localState }))}
                maxLength={iuCharacterLimit}
            />

            <p className={newClassNames.TextArea__description}>
                Character count: {(localState || '').toString().length} / {iuCharacterLimit}
            </p>

            <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
        </div>
    );
};

interface IFormTextAreaProps {
    yupValueKey: keyof StudentAnswersObj;
    iuCharacterLimit?: number;
    iuRows?: number;
    children: JSX.Element | JSX.Element[];
}
export const FormTextArea = (props: IFormTextAreaProps) => {
    const { yupValueKey, children, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return (
        <TextArea {...otherProps} {...validity} value={studentAnswers[yupValueKey]} yupValueKey={yupValueKey} setValues={setStudentAnswers as any}>
            {children}
        </TextArea>
    );
};
