// LOGIN
export type LoginDataObj = {
    username: string;
    password: string;
};

export const emptyLoginObj = {
    username: '',
    password: '',
};

export type LoginDataErrors = {
    username: string[];
    password: string[];
};

export const emptyLoginErrors = {
    username: [],
    password: [],
};

//SIGNUP
export type SignUpDataObj = {
    username: string;
    password: string;
};

export const emptySignUpObj = {
    username: '',
    password: '',
};

export type SignUpErrors = {
    username: string[];
    password: string[];
};

export const emptySignUpErrors = {
    username: [],
    password: [],
};

//PASSWORD RESET
export type PasswordResetObj = {
    username: string;
    password: string;
    confirm_password: string;
    id: string;
};

export const emptyPasswordResetObj = {
    username: '',
    password: '',
    confirm_password: '',
    id: '',
};

export type PasswordResetErrors = {
    username: string[];
    password: string[];
    confirm_password: string[];
    id: string[];
};

export const emptyPasswordResetErrors = {
    username: [],
    password: [],
    confirm_password: [],
    id: [],
};

//CONFIRM
export type ConfirmObj = {
    username: string;
    code: string;
};

export const emtpyConfirmObj = { username: '', code: '' };

export type ConfirmErrors = {
    username: string[];
    code: string[];
};

export const emptyConfirmErrors = {
    username: [],
    code: [],
};
