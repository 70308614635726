import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import './MobileResponsive.scss';

interface ResponsiveLayoutProps {
    children: JSX.Element | JSX.Element[];
    iuCustomStyle?: ICustomStyle[];
}

export const ResponsiveLayout = (props: ResponsiveLayoutProps) => {
    const { children, iuCustomStyle } = props;

    const classNames = {
        ResponsiveLayout: 'ResponsiveLayout',
        ResponsiveLayout__content: 'ResponsiveLayout__content',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.ResponsiveLayout}>
            <div className={newClassNames.ResponsiveLayout__content}>{children}</div>
        </div>
    );
};
