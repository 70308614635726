import { AutoCompleteCategoryEnum } from '../Components/BasicForm/FormAutoComplete/FormAddressBlock';
import { IAutoCompleteOption } from '../Components/BasicForm/FormAutoComplete/FormAutoComplete';
import { formatAddressPrefix } from '../configBuilder/configUtil';
import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';
import { statusHandler } from './statusHandler';

export const getLookupOptions = async (inputValue: string, category: AutoCompleteCategoryEnum) => {
    try {
        const data = await statusHandler(fetch, `/public/lookup?text=${inputValue}&category=${category}&max_results=5`, 'GET');
        return data.map((item: any) => ({ label: item.text, value: item.id, subtext: item.sub_text }));
    } catch {
        return [];
    }
};

export const getExactLookup = async (
    desiredPlace: IAutoCompleteOption,
    setValues: (values: React.SetStateAction<StudentAnswersObj>) => void,
    setLocalState: (localState: React.SetStateAction<IAutoCompleteOption>) => void,
    prefix: string,
    category: AutoCompleteCategoryEnum,
    yupValueKeys: string[]
) => {
    const localStateValueKey = category === AutoCompleteCategoryEnum.address ? 'address' : 'name';

    try {
        const exactAddress = await statusHandler(fetch, `/public/lookup?id=${desiredPlace.value}&category=${category}`, 'GET');
        const prefixAddress = buildLookupValues(exactAddress, prefix, yupValueKeys);
        setValues(prevValue => ({
            ...prevValue,
            ...prefixAddress,
        }));
        setLocalState(prevValue => ({ ...prevValue, label: exactAddress[localStateValueKey] }));
    } catch {
        setValues(prevValue => ({ ...prevValue, [localStateValueKey]: desiredPlace.label }));
        setLocalState(desiredPlace);
    }
};

function buildLookupValues(exactAddress: { [key: string]: string }, prefix: string, yupValueKeys: string[]) {
    // don't allow other data that AWS returns into our data model
    const formattedValues = yupValueKeys.map(key => [formatAddressPrefix(prefix, key), exactAddress[key] || '']);
    return Object.fromEntries(formattedValues);
}
