export const defaultReactQueryOptions = {
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
};

export const getCustomQueryClientOptions = (setErrorModalMessage: (errorModalMessage: string) => void) => ({
    mutations: {
        onError: (err: any) => setErrorModalMessage(err.message),
        ...defaultReactQueryOptions,
    },
    queries: {
        onError: (err: any) => setErrorModalMessage(err.message),
        ...defaultReactQueryOptions,
    },
});
