export type primitives = string | number | boolean | undefined | null;
interface IStudentAnswers {
    fname: string;
    mname: string;
    lname: string;
    pname: string;
    gender: string;
    gender_identity: string;
    preferred_pronoun: string;
    email: string;
    phone_type: string;
    birth_day: string;
    phone_no_cell_area: string;
    cell_txt_opt_in: boolean;
    address: string;
    apt_num: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    mail_same_as_perm: string;
    mailing_address: string;
    mailing_apt_num: string;
    mailing_city: string;
    mailing_state: string;
    mailing_zip: string;
    mailing_country: string;
    mail_eff_date_day: string;
    citizen_type: string;
    citizen_country: string;
    citizen_visa_status: string;
    english_radio: string;
    native_language: string;
    ethnicity_hispanic: string;
    race_american_indian: boolean;
    race_asian: boolean;
    race_black: boolean;
    race_native_hawaiian: boolean;
    race_white: boolean;
    military_status: string;
    military_status_specified: string;
    military_branch: string;
    app_major: string;
    app_major2: string;
    enroll_term: string;
    msw_standing_schedule: string;
    bach_social_work: string;
    cacrep_masters: string;
    teaching_license: string;
    msw_accelerated_explain: string;
    prev_attend: string;
    prev_attend_from_month: string;
    prev_attend_from_year: string;
    prev_attend_to_month: string;
    prev_attend_to_year: string;
    college_0_ceeb: string;
    college_0_name: string;
    college_0_addr1: string;
    college_0_city: string;
    college_0_state: string;
    college_0_zip: string;
    degree_earned_0: string;
    gpa_0: string;
    credits_earned_0: string;
    attended_from_month_0: string;
    attended_from_year_0: string;
    attended_to_month_0: string;
    attended_to_year_0: string;
    college_1_ceeb: string;
    college_1_name: string;
    college_1_addr1: string;
    college_1_city: string;
    college_1_state: string;
    college_1_zip: string;
    degree_earned_1: string;
    gpa_1: string;
    credits_earned_1: string;
    attended_from_month_1: string;
    attended_from_year_1: string;
    attended_to_month_1: string;
    attended_to_year_1: string;
    college_2_ceeb: string;
    college_2_name: string;
    college_2_addr1: string;
    college_2_city: string;
    college_2_state: string;
    college_2_zip: string;
    degree_earned_2: string;
    gpa_2: string;
    credits_earned_2: string;
    attended_from_month_2: string;
    attended_from_year_2: string;
    attended_to_month_2: string;
    attended_to_year_2: string;
    college_3_ceeb: string;
    college_3_name: string;
    college_3_addr1: string;
    college_3_city: string;
    college_3_state: string;
    college_3_zip: string;
    degree_earned_3: string;
    gpa_3: string;
    credits_earned_3: string;
    attended_from_month_3: string;
    attended_from_year_3: string;
    attended_to_month_3: string;
    attended_to_year_3: string;
    college_4_ceeb: string;
    college_4_name: string;
    college_4_addr1: string;
    college_4_city: string;
    college_4_state: string;
    college_4_zip: string;
    degree_earned_4: string;
    gpa_4: string;
    credits_earned_4: string;
    attended_from_month_4: string;
    attended_from_year_4: string;
    attended_to_month_4: string;
    attended_to_year_4: string;
    previous_grad_coursework: string;
    previous_grad_coursework_schools: string;
    toefl_taken: string;
    toefl_taken_month: string;
    toefl_taken_year: string;
    toefl_score: string;
    toefl_plan_month: string;
    toefl_plan_year: string;
    suspended: string;
    suspended_explain: string;
    agree_counselor_request_waiver: string;
    legal_agree: boolean;
}

export type IStudentDataErrors = Record<keyof IStudentAnswers, string[] | undefined>;

export type StudentAnswersObj = Record<keyof IStudentAnswers, primitives>;

export const emptyStudentAnswersObj = {} as StudentAnswersObj;
export const emptyStudentDataErrors = {} as IStudentDataErrors;
