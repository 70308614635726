import React, { useEffect, useState } from 'react';
import { ConfirmErrors, ConfirmObj, emptyConfirmErrors, emtpyConfirmObj } from '../../../interfaces/AccountDataTypes';
import { TextInput } from '../../BasicForm/FormTextInput/FormTextInput';
import { BasicAuthBase } from '../SharedAccount';
import { useLocation } from 'react-router-dom';
import ButtonWithIcon from '../../BasicUtil/Buttons/ButtonWithIcon';
import { formatClassNames } from '../../../configBuilder/configUtil';
import BasicToast from '../../UserFeedback/BasicToast/BasicToast';
import { formatErrorMessage } from '../../../api/statusHandler';
import { usePostConfirmationData } from '../../../api/usePostConfirmationData';
import { usePostResendConfirmationCode } from '../../../api/usePostResendConfirmationCode';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant, SeverityVariant } from '../../../interfaces/VariationTypes';
import { confirmSchema } from '../../../configBuilder/devYupSchemas';

interface IConfirmCardProps {
    setSuccessMessage: (successMessage: string) => void;
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
}

export const ConfirmCard = (props: IConfirmCardProps) => {
    const { setSuccessMessage, iuCustomStyle, iuVariant, ...otherProps } = props;
    const [signUpValues, setSignUpValues] = useState<ConfirmObj>(emtpyConfirmObj);
    const [yupErrors, setYupErrors] = useState<ConfirmErrors>(emptyConfirmErrors);

    const location = useLocation();
    const postConfirmData = usePostConfirmationData(setSuccessMessage);
    const postResendConfirmationCode = usePostResendConfirmationCode(setSuccessMessage, signUpValues.username);

    const classNames = {
        ConfirmCard__resendConfirmCodeButton: 'ConfirmCard__resendConfirmCodeButton',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    useEffect(() => {
        if (typeof location.state === 'object') {
            setSignUpValues(prevValue => ({ ...prevValue, ...location.state }));
        }
    }, [location.state]);

    return (
        <BasicAuthBase
            setAccountValues={setSignUpValues}
            accountValues={signUpValues}
            emptyAccountErrors={emptyConfirmErrors}
            yupSchema={confirmSchema}
            yupErrors={yupErrors}
            setYupErrors={setYupErrors}
            mutationHandler={postConfirmData}
            setSuccessMessage={setSuccessMessage}
            iuCustomStyle={iuCustomStyle}
            {...otherProps}
        >
            <BasicToast
                theme={SeverityVariant.error}
                message={postResendConfirmationCode.isError ? formatErrorMessage(postResendConfirmationCode.error) : ''}
                hasClose={false}
            />
            <div>
                <TextInput<ConfirmObj>
                    iuLabel="Confirmation Code"
                    setValues={setSignUpValues}
                    yupValueKey="code"
                    value={signUpValues.code}
                    yupError={yupErrors.code}
                    iuCustomStyle={iuCustomStyle}
                    iuVariant={iuVariant}
                    iuAutoComplete="one-time-code"
                />
                <ButtonWithIcon
                    actionCallback={() => postResendConfirmationCode.mutate(signUpValues.username)}
                    iuButtonText="Resend confirmation code?"
                    className={newClassNames.ConfirmCard__resendConfirmCodeButton}
                />
            </div>
        </BasicAuthBase>
    );
};
