import { IAnswerType } from '../../../interfaces/QuestionAnswerTypes';
import ValidationError from '../FormValidationError/FormValidationError';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { Label } from '@radix-ui/react-label';
import { RadioGroup, RadioGroupItem, RadioGroupIndicator } from '@radix-ui/react-radio-group';
import './FormRadioInput.scss';
import { IFormComponentProps, primitives } from '../../../interfaces/StudentProfileTypes';
import React from 'react';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';

interface IRadioInputProps<T extends SupportedComplexObjs> {
    iuLabel: string;
    setValues: (value: React.SetStateAction<T>) => void;
    yupValueKey: keyof T;
    iuAnswers: IAnswerType[];
    yupError: string[];
    value?: primitives;
    iuCustomStyle?: ICustomStyle[];
}

export const RadioInputWithoutMemo = <T extends SupportedComplexObjs>(props: IRadioInputProps<T>) => {
    const { iuLabel, setValues, yupValueKey, iuAnswers, yupError, value, iuCustomStyle } = props;
    const valueKey = yupValueKey.toString();

    const classNames = {
        FormRadio: 'FormRadio',
        FormRadio__label: 'FormRadio__label',
        FormRadio__radioContainer: 'FormRadio__radioContainer',
        FormRadio__input: 'FormRadio__input',
        FormRadio__radio: 'FormRadio__radio',
        FormRadio__radioItem: 'FormRadio__radioItem',
        FormRadio__input_checked: 'FormRadio__input_checked',
        FormRadio__radioLabel: 'FormRadio__radioLabel',
        FormRadio__radioIndicator: 'FormRadio__radioIndicator',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <div className={newClassNames.FormRadio}>
            <Label id={valueKey} className={newClassNames.FormRadio__label}>
                {iuLabel}
            </Label>
            <div>
                <RadioGroup
                    aria-labelledby={valueKey}
                    aria-invalid={yupError.length > 0}
                    className={newClassNames.FormRadio__radioContainer}
                    onValueChange={value => setValues(prevValue => ({ ...prevValue, [yupValueKey]: value }))}
                    value={value?.toString()}
                    aria-describedby={`${valueKey}-error`}
                >
                    {iuAnswers.map(answer => {
                        const idSafeLabel = answer.iuLabel.replace(/ /g, '-');
                        const isSelected = value === answer.iuValue;
                        return (
                            <div key={idSafeLabel} className={newClassNames.FormRadio__radioItem}>
                                <RadioGroupItem
                                    className={`${newClassNames.FormRadio__radio} ${
                                        isSelected ? newClassNames.FormRadio__input_checked : newClassNames.FormRadio__input
                                    }`}
                                    value={answer.iuValue.toString()}
                                    aria-labelledby={`${idSafeLabel}-${valueKey}`}
                                >
                                    <RadioGroupIndicator className={newClassNames.FormRadio__radioIndicator} />
                                </RadioGroupItem>
                                <Label id={`${idSafeLabel}-${valueKey}`} className={newClassNames.FormRadio__radioLabel}>
                                    {answer.iuLabel}
                                </Label>
                            </div>
                        );
                    })}
                </RadioGroup>
                <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};

export const RadioInput = React.memo(RadioInputWithoutMemo) as typeof RadioInputWithoutMemo;

interface IFormRadioProps extends IFormComponentProps {
    iuAnswers: IAnswerType[];
}
export const FormRadioInput = (props: IFormRadioProps) => {
    const { yupValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return <RadioInput {...otherProps} value={studentAnswers[yupValueKey]} yupValueKey={yupValueKey} {...validity} setValues={setStudentAnswers} />;
};
