import React from 'react';
import { createRoot } from 'react-dom/client';
import config from './config.json';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IRouteObj } from './interfaces/ConfigLangTypes';
import BuildConfigLang from './Components/App/BuildConfigLang';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();

// required wrappings
const app = React.createElement(BuildConfigLang, { iuConfigObj: config as IRouteObj[], key: 'main-app' }, null);
const broswerRouter = React.createElement(BrowserRouter, { key: 'browser-router' }, [app]);
const reactQueryDevTools = React.createElement(ReactQueryDevtools, { key: 'dev-tools' });
const queryClientProvider = React.createElement(QueryClientProvider, { client: queryClient, key: 'query-client' }, [broswerRouter, reactQueryDevTools]);

const strictMode = React.createElement(React.StrictMode, { key: 'strict-mode' }, [queryClientProvider]);

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(strictMode);
