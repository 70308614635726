import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { Label } from '@radix-ui/react-label';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import { ToggleVisibility } from '../../BasicUtil/ToggleVisibility/ToggleVisibility';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';

interface IFormMaskedSsnProps {
    yupValueKey: keyof StudentAnswersObj;
    iuLabel: string;
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
}

export const FormMaskedSsn = (props: IFormMaskedSsnProps) => {
    const { yupValueKey, iuLabel, iuCustomStyle, iuVariant } = props;
    const valueKey = yupValueKey.toString();

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const value = studentAnswers[yupValueKey];

    const validity = useGetAnswersValidity(yupValueKey);

    const handleSsnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const matchValues = e.target.value.replace(/\D/g, '').match(/(\d{0,3})-?(\d{0,2})-?(\d{0,4})/);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, areaNumber, groupNumber, serialNumber] = matchValues ?? [];
        const maskedSsn = (areaNumber ? `${areaNumber}` : '') + (groupNumber ? `-${groupNumber}` : '') + (serialNumber ? `-${serialNumber}` : '');
        setStudentAnswers(prevValue => ({ ...prevValue, [yupValueKey]: maskedSsn }));
    };

    const classNames = {
        BasicForm: 'BasicForm',
        BasicForm__question: 'BasicForm__question',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.BasicForm}>
            <Label className={newClassNames.BasicForm__question} id={valueKey}>
                {iuLabel}
            </Label>
            <div>
                <ToggleVisibility<StudentAnswersObj>
                    yupValueKey={yupValueKey}
                    iuCustomStyle={iuCustomStyle}
                    value={value}
                    handleChange={handleSsnChange}
                    {...validity}
                    iuVariant={iuVariant}
                    iuAutoComplete="one-time-code"
                />
            </div>
        </div>
    );
};
