import { formatClassNames, formatAddressPrefix } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { BasicSeparator } from '../../BasicUtil/BasicSeparator/BasicSeparator';
import { AutoCompleteCategoryEnum } from './FormAddressBlock';
import { FormSelectSingle } from '../FormSelect/FormSelect';
import { FormTextInput } from '../FormTextInput/FormTextInput';
import { FormAutoComplete } from './FormAutoComplete';

interface IFormCeebBlock {
    iuCustomStyle?: ICustomStyle[];
    prefix?: string;
    hasSeparator?: boolean;
    iuVariant?: TextInputVariant;
}

export const FormCeebBlock = (props: IFormCeebBlock) => {
    const { iuCustomStyle, prefix = '', hasSeparator, iuVariant } = props;

    const classNames = {
        BasicFormSection: 'BasicFormSection',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    const separator = hasSeparator ? <BasicSeparator orientation="horizontal" iuCustomStyle={iuCustomStyle} /> : undefined;

    return (
        <div className={newClassNames.BasicFormSection}>
            <FormAutoComplete
                iuLabel="Search for Your School"
                yupValueKey={formatAddressPrefix(prefix, 'name')}
                iuCustomStyle={iuCustomStyle}
                prefix={prefix}
                category={AutoCompleteCategoryEnum.college_ceeb}
                yupValueKeys={['name', 'addr1', 'city', 'state', 'zip', 'ceeb']}
            />
            {separator}
            <FormTextInput iuLabel="Address" yupValueKey={formatAddressPrefix(prefix, 'addr1')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
            {separator}
            <FormTextInput iuLabel="City" yupValueKey={formatAddressPrefix(prefix, 'city')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
            {separator}
            <FormSelectSingle iuLabel="State/Province" yupValueKey={formatAddressPrefix(prefix, 'state')} iuCustomStyle={iuCustomStyle} dropdownOption="state" />
            {separator}
            <FormTextInput iuLabel="Zip/Postal Code" yupValueKey={formatAddressPrefix(prefix, 'zip')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
        </div>
    );
};
