import './BasicToast.scss';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@radix-ui/react-toast';
import MaterialIcon from '../../BasicUtil/MaterialIcon';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { SeverityVariant } from '../../../interfaces/VariationTypes';

interface IBasicToastProps {
    setMessage?: (message: string) => void;
    message: string;
    hasClose: boolean;
    iuCustomStyle?: ICustomStyle[];
    theme?: keyof typeof SeverityVariant;
}

const BasicToast = (props: IBasicToastProps) => {
    const { setMessage, message, hasClose, iuCustomStyle, theme = SeverityVariant.success } = props;

    type AriaLiveTypes = 'off' | 'assertive' | 'polite' | undefined;

    const getVariantMode = () => {
        if (theme === SeverityVariant.success) {
            return { title: 'Success', icon: 'task_alt', role: 'region', ariaLive: 'polite' as AriaLiveTypes };
        }
        return { title: 'Error', icon: 'error', role: 'alert', ariaLive: 'assertive' as AriaLiveTypes };
    };
    const closingProps = typeof setMessage === 'undefined' ? {} : { onOpenChange: () => setMessage('') };

    const classNames = {
        [`BasicToast_${theme}`]: `BasicToast_${theme}`,
        [`BasicToast__title_${theme}`]: `BasicToast__title_${theme}`,
        [`BasicToast__successIcon_${theme}`]: `BasicToast__successIcon_${theme}`,
        [`BasicToast__close_${theme}`]: `BasicToast__close_${theme}`,
        [`BasicToast__closeIcon_${theme}`]: `BasicToast__closeIcon_${theme}`,
        [`BasicToast__description_${theme}`]: `BasicToast__description_${theme}`,
        [`BasicToast__viewport_${theme}`]: `BasicToast__viewport_${theme}`,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <ToastProvider swipeDirection="right">
            <Toast
                role={getVariantMode().role}
                aria-live={getVariantMode().ariaLive}
                duration={5000}
                className={newClassNames[`BasicToast_${theme}`]}
                open={!!message}
                {...closingProps}
            >
                <MaterialIcon iconName={getVariantMode().icon} className={newClassNames[`BasicToast__successIcon_${theme}`]} iuCustomStyle={iuCustomStyle} />
                <ToastTitle className={newClassNames[`BasicToast__title_${theme}`]}>{getVariantMode().title}</ToastTitle>
                {hasClose ? (
                    <ToastClose className={newClassNames[`BasicToast__close_${theme}`]}>
                        <span className={'material-symbols-outlined ' + newClassNames[`BasicToast__closeIcon_${theme}`]}>close</span>
                    </ToastClose>
                ) : undefined}
                <ToastDescription className={newClassNames[`BasicToast__description_${theme}`]}>{message}</ToastDescription>
            </Toast>
            <ToastViewport className={newClassNames[`BasicToast__viewport_${theme}`]} />
        </ToastProvider>
    );
};

export default BasicToast;
