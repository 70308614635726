import { formatClassNames } from '../../configBuilder/configUtil';
import { ICustomStyle } from '../../interfaces/CustomStyleTypes';

interface IMaterialIconProps {
    iconName: string;
    className?: string;
    iuCustomStyle?: ICustomStyle[];
}

const MaterialIcon = (props: IMaterialIconProps) => {
    const { iconName, iuCustomStyle, className = 'MaterialIcon', ...otherProps } = props;

    const classNames = {
        [className]: className,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <span {...otherProps} className={`material-symbols-outlined ${newClassNames[className]}`}>
            {iconName}
        </span>
    );
};

export default MaterialIcon;
