import { useState } from 'react';
import { TextInput } from '../../BasicForm/FormTextInput/FormTextInput';
import { BasicAuthBase } from '../SharedAccount';
import { ToggleVisibility } from '../../BasicUtil/ToggleVisibility/ToggleVisibility';
import { Label } from '@radix-ui/react-label';
import { formatClassNames, getAllYupErrors } from '../../../configBuilder/configUtil';
import { usePostLoginData } from '../../../api/usePostLoginData';
import { LoginDataObj, emptyLoginObj, LoginDataErrors, emptyLoginErrors } from '../../../interfaces/AccountDataTypes';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { SeverityVariant, TextInputVariant } from '../../../interfaces/VariationTypes';
import { usePostResendConfirmationCode } from '../../../api/usePostResendConfirmationCode';
import { formatErrorMessage } from '../../../api/statusHandler';
import ButtonWithIcon from '../../BasicUtil/Buttons/ButtonWithIcon';
import BasicToast from '../../UserFeedback/BasicToast/BasicToast';
import { loginSchema } from '../../../configBuilder/devYupSchemas';

interface ILoginCard {
    setSuccessMessage: (successMessage: string) => void;
    iuCustomStyle?: ICustomStyle[];
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    iuVariant?: TextInputVariant;
}

export const LoginCard = (props: ILoginCard) => {
    const { setSuccessMessage, iuCustomStyle, iuVariant, ...otherProps } = props;
    const [loginValues, setLoginValues] = useState<LoginDataObj>(emptyLoginObj);
    const [yupErrors, setYupErrors] = useState<LoginDataErrors>(emptyLoginErrors);
    const [resendErrorMessage, setResendErrorMessage] = useState('');
    const postLoginData = usePostLoginData(setSuccessMessage);
    const postResendConfirmationCode = usePostResendConfirmationCode(setSuccessMessage, loginValues.username);

    const classNames = {
        LoginCard__passwordReset: 'LoginCard__passwordReset',
        LoginCard__resendConfirmCodeButton: 'LoginCard__resendConfirmCodeButton',
        LoginCard__resendConfirmCodeButtonContainer: 'LoginCard__resendConfirmCodeButtonContainer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    const resendConfirmCodeActionCallback = async () => {
        const yupErrors = await getAllYupErrors(loginSchema, { username: loginValues.username });
        if (yupErrors.username) {
            setResendErrorMessage('You must provide a valid email address to re-send a confirmation code.');
        } else {
            postResendConfirmationCode.mutate(loginValues.username);
            setResendErrorMessage('');
        }
    };

    return (
        <BasicAuthBase
            accountValues={loginValues}
            setAccountValues={setLoginValues}
            emptyAccountErrors={emptyLoginErrors}
            yupSchema={loginSchema}
            yupErrors={yupErrors}
            setYupErrors={setYupErrors}
            mutationHandler={postLoginData}
            setSuccessMessage={setSuccessMessage}
            iuCustomStyle={iuCustomStyle}
            {...otherProps}
        >
            <BasicToast
                theme={SeverityVariant.error}
                message={resendErrorMessage || (postResendConfirmationCode.error && formatErrorMessage(postResendConfirmationCode.error))}
                hasClose={false}
            />
            <TextInput<LoginDataObj>
                iuLabel="Email Address"
                setValues={setLoginValues}
                yupValueKey="username"
                value={loginValues.username}
                yupError={yupErrors.username}
                iuCustomStyle={iuCustomStyle}
                iuAutoComplete="username"
                iuVariant={iuVariant}
            />
            <div>
                <Label id="password">Password</Label>
                <ToggleVisibility<LoginDataObj>
                    yupValueKey="password"
                    value={loginValues.password}
                    handleChange={event => setLoginValues(prevValue => ({ ...prevValue, password: event.target.value }))}
                    yupError={yupErrors.password}
                    iuCustomStyle={iuCustomStyle}
                    iuVariant={iuVariant}
                    iuAutoComplete="current-password"
                />
                <div className={newClassNames.LoginCard__resendConfirmCodeButtonContainer}>
                    <ButtonWithIcon
                        actionCallback={resendConfirmCodeActionCallback}
                        iuButtonText="Resend confirmation code?"
                        className={newClassNames.LoginCard__resendConfirmCodeButton}
                    />
                </div>
                <a className={newClassNames.LoginCard__passwordReset} href="/forgot">
                    Forgot password?
                </a>
            </div>
        </BasicAuthBase>
    );
};
