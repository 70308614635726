import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';

interface IStudentAnswerDropinProps {
    yupValueKey: keyof StudentAnswersObj;
}
export const StudentAnswerDropin = (props: IStudentAnswerDropinProps) => {
    const { yupValueKey } = props;
    const { studentAnswers } = useStudentAnswers();

    return <>{studentAnswers[yupValueKey]}</>;
};
