import { getSelectOptions, evaluateConditionals, useClearOnHide } from '../../../configBuilder/configUtil';
import { IConditionObj } from '../../../interfaces/ConditionTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { IFormSelectSingleProps, FormSelectSingle } from './FormSelect';

interface IDependentSelectProps extends IFormSelectSingleProps {
    yupValueKeyToWatch: keyof StudentAnswersObj;
    iuIsMatched?: IConditionObj<StudentAnswersObj>;
    renderProps?: {
        elementAbove?: JSX.Element | JSX.Element[];
        elementBelow?: JSX.Element | JSX.Element[];
    };
}
export const FormDependentSelect = (props: IDependentSelectProps) => {
    const { yupValueKeyToWatch, iuLabel, yupValueKey, iuIsMatched, renderProps, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();

    // clear on hide logic
    const valueToWatch = studentAnswers[yupValueKeyToWatch];
    useClearOnHide({
        setStudentAnswers,
        valueToWatch,
        answerKeysToClear: [yupValueKey],
    });

    const hasSelectOptions = (dropdownKey: string) => {
        const dropdownOptions = getSelectOptions(dropdownKey);
        return dropdownOptions.size > 0;
    };

    const renderDetails = {
        shouldRender: iuIsMatched ? evaluateConditionals(iuIsMatched, studentAnswers) : valueToWatch && hasSelectOptions(`${yupValueKey}_${valueToWatch}`),
        dropdownSuffix: iuIsMatched ? iuIsMatched.dropdownOption : valueToWatch,
    };

    return (
        <>
            {renderDetails.shouldRender ? (
                <>
                    {renderProps?.elementAbove ? renderProps.elementAbove : undefined}
                    <FormSelectSingle {...otherProps} iuLabel={iuLabel} yupValueKey={yupValueKey} dropdownOption={`${yupValueKey}_${renderDetails.dropdownSuffix}`} />
                    {renderProps?.elementBelow ? renderProps.elementBelow : undefined}
                </>
            ) : undefined}
        </>
    );
};
