import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { LoadingSizes, ThemeVariant } from '../../../interfaces/VariationTypes';
import LoadingCircles from '../Loading/LoadingCircles';
import MaterialIcon from '../MaterialIcon';
import './ButtonWithIcon.scss';

interface IButtonWithIconProps {
    className?: string;
    iuLeadingIcon?: string;
    iuTrailingIcon?: string;
    iuButtonText: string;
    actionCallback?: () => void;
    type?: 'button' | 'reset' | 'submit';
    iuCustomStyle?: ICustomStyle[];
    isLoading?: boolean;
    loadingVariantSize?: keyof typeof LoadingSizes;
    iuVariant?: ThemeVariant;
}

const ButtonWithIcon = (props: IButtonWithIconProps) => {
    const {
        className,
        iuLeadingIcon,
        iuTrailingIcon,
        iuButtonText,
        actionCallback = () => {},
        type = 'button',
        iuCustomStyle,
        isLoading,
        loadingVariantSize = 'xs',
        iuVariant = ThemeVariant.dark,
    } = props;

    const classNames = {
        ButtonWithIcon: className ? className : 'ButtonWithIcon',
        ButtonWithIcon__loadingContainer: 'ButtonWithIcon__loadingContainer',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <button disabled={isLoading} onClick={actionCallback} type={type} className={className ? className : newClassNames.ButtonWithIcon}>
            {iuLeadingIcon ? <MaterialIcon iconName={iuLeadingIcon} className={newClassNames.MaterialIcon} /> : undefined}
            {isLoading ? (
                <div className={newClassNames.ButtonWithIcon__loadingContainer}>
                    <LoadingCircles size={loadingVariantSize} iuVariant={iuVariant} />
                </div>
            ) : (
                iuButtonText
            )}
            {iuTrailingIcon ? <MaterialIcon iconName={iuTrailingIcon} className={newClassNames.MaterialIcon} /> : undefined}
        </button>
    );
};

export default ButtonWithIcon;
