import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from '@radix-ui/react-popover';
import './AccountPopover.scss';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import ButtonWithIcon from '../../BasicUtil/Buttons/ButtonWithIcon';
import LoadingCircles from '../../BasicUtil/Loading/LoadingCircles';
import { ThemeVariant } from '../../../interfaces/VariationTypes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useClientSnapshot } from '../../../stateManagement/useClientSnapshot';
import { useGetLogout } from '../../../api/useGetLogout';
import { SaveButton } from '../../BasicUtil/Buttons/ActionButtons';
import { useIsMutating } from 'react-query';

interface IAccountPopoverProps {
    iuSideOffset?: number;
    iuCustomStyle?: ICustomStyle[];
    setSuccessMessage: (successMessage: string) => void;
}

const AccountPopover = (props: IAccountPopoverProps) => {
    const { iuSideOffset = 5, iuCustomStyle, setSuccessMessage } = props;

    const [performLogout, setPerformLogout] = useState(false);

    const navigate = useNavigate();
    const isStudentDataLoading = useIsMutating({ mutationKey: 'studentAnswers' }) > 0;

    const { clientSnapshot } = useClientSnapshot();
    const getAccountLogout = useGetLogout(performLogout, setPerformLogout);

    const logoutAction = () => {
        setPerformLogout(true);
    };

    const classNames = {
        AccountPopover__accountIcon: 'AccountPopover__accountIcon',
        AccountPopover__container: 'AccountPopover__container',
        AccountPopover__actionContainer: 'AccountPopover__actionContainer',
        AccountPopover__trigger: 'AccountPopover__trigger',
        accountTrigger: 'AccountPopover__accountTrigger',
        AccountPopover__close: 'AccountPopover__close',
        AccountPopover__closeIcon: 'AccountPopover__closeIcon',
        AccountPopover__loginButton: 'AccountPopover__loginButton',
        AccountPopover__accountButton: 'AccountPopover__accountButton',
        AccountPopover__accountButtonContainer: 'AccountPopover__accountButtonContainer',
        AccountPopover__emailIcon: 'AccountPopover__emailIcon',
        AccountPopover__emailContainer: 'AccountPopover__emailContainer',
        AccountPopover__email: 'AccountPopover__email',
        AccountPopover__accountTrigger: 'AccountPopover__accountTrigger',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <Popover>
            <PopoverTrigger className={newClassNames.AccountPopover__trigger}>
                <span className={newClassNames.AccountPopover__accountTrigger}>
                    <span className={`material-symbols-outlined ${newClassNames.AccountPopover__accountIcon}`}>account_circle</span>
                    <div>Account</div>
                </span>
            </PopoverTrigger>
            <PopoverContent className={newClassNames.AccountPopover__container} sideOffset={iuSideOffset}>
                <PopoverClose className={newClassNames.AccountPopover__close} aria-label="Close">
                    <span className={`material-symbols-outlined ${newClassNames.AccountPopover__closeIcon}`}>close</span>
                </PopoverClose>
                <div className={newClassNames.AccountPopover__actionContainer}>
                    {clientSnapshot.is_logged_in ? (
                        <div>
                            <ButtonWithIcon
                                className={newClassNames.AccountPopover__loginButton}
                                iuButtonText="Log Out"
                                actionCallback={logoutAction}
                                isLoading={getAccountLogout.isLoading}
                            />
                            <div className={newClassNames.AccountPopover__emailContainer}>
                                <span className={`material-symbols-outlined ${newClassNames.AccountPopover__emailIcon}`}>mail</span>
                                <p className={newClassNames.AccountPopover__email}>{clientSnapshot.username}</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <ButtonWithIcon className={newClassNames.AccountPopover__loginButton} iuButtonText="Sign In" actionCallback={() => navigate('/login')} />
                            <ButtonWithIcon
                                className={newClassNames.AccountPopover__accountButton}
                                iuButtonText="Create Account"
                                actionCallback={() => navigate('/signup')}
                            />
                        </div>
                    )}
                    <div className={newClassNames.AccountPopover__accountButtonContainer}>
                        <SaveButton className={newClassNames.AccountPopover__accountButton} setSuccessMessage={setSuccessMessage} iuButtonText="Save Progress" />
                        {isStudentDataLoading ? <LoadingCircles size="xs" iuVariant={ThemeVariant.primary} /> : undefined}
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
};

export default AccountPopover;
