import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { statusHandler } from './statusHandler';

export const usePutLastPage = () => {
    const putLastPage = useMutation((newPage: string) => statusHandler(fetch, '/api/last-page', 'PUT', { last_page: newPage }), {
        ...defaultReactQueryOptions,
        onSuccess: () => {},
        onError: () => {},
    });
    return putLastPage;
};
