import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';
import { ICustomStyle } from './CustomStyleTypes';
import { TextInputVariant } from './VariationTypes';

export type primitives = string | number | boolean | undefined | null;

export enum StudentWebStatusEnum {
    started = 'started',
    validating = 'validating',
    submitted = 'submitted',
}

export interface IClientSnapshot {
    web_status: StudentWebStatusEnum | null;
    is_logged_in: boolean | null;
    last_page: string | null;
    username: string | null;
}

export type ClientSnapshot = Record<keyof IClientSnapshot, primitives>;

export const emptyClientSnapshot = {
    web_status: null,
    last_page: null,
    is_logged_in: null,
    username: null,
};

export interface IFormComponentProps {
    yupValueKey: keyof StudentAnswersObj;
    iuLabel: string;
    iuAutoComplete?: string;
    iuVariant?: TextInputVariant;
    iuCustomStyle?: ICustomStyle[];
}

export interface IApiWebStatus {
    web_status: StudentWebStatusEnum;
    dt_added: string;
}

export interface IProfileObj {
    answer: StudentAnswersObj;
    web_status: IApiWebStatus[];
    last_page: string;
    username: string;
}
