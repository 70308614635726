import { useNavigate } from 'react-router-dom';
import ButtonWithIcon from './ButtonWithIcon';
import { MutableRefObject } from 'react';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useClientSnapshot } from '../../../stateManagement/useClientSnapshot';
import { StudentWebStatusEnum } from '../../../interfaces/StudentProfileTypes';
import { usePostWebStatus } from '../../../api/usePostWebStatus';
import { ThemeVariant } from '../../../interfaces/VariationTypes';
import { usePostStudentData } from '../../../api/usePostStudentData';
import { getAllYupErrors } from '../../../configBuilder/configUtil';
import { completeSchema } from '../../../tenantConfig/dataModel/yupSchemas';

export interface IActionButtonProps {
    iuButtonText: string;
    iuLeadingIcon?: string;
    iuTrailingIcon?: string;
    iuCustomStyle?: ICustomStyle[];
    className?: string;
}

interface ISaveButtonProps extends IActionButtonProps {
    setSuccessMessage: (successMessage: string) => void;
    iuTargetRoute?: string;
    topOfPageRef?: MutableRefObject<HTMLDivElement | null>;
}

export const SaveButton = (props: ISaveButtonProps) => {
    const { iuTargetRoute, topOfPageRef, setSuccessMessage, ...otherProps } = props;

    const postStudentData = usePostStudentData(setSuccessMessage);
    const { studentAnswers } = useStudentAnswers();
    const { clientSnapshot } = useClientSnapshot();

    const navigate = useNavigate();
    const handleSaveAndReRoute = () => {
        if (clientSnapshot.is_logged_in) {
            postStudentData.mutate(studentAnswers);
            if (iuTargetRoute) {
                navigate(iuTargetRoute);
                (topOfPageRef?.current || { scrollIntoView: () => {} }).scrollIntoView();
            }
        } else {
            navigate('/signup');
        }

        (topOfPageRef?.current || { scrollIntoView: () => {} }).scrollIntoView();
    };
    return <ButtonWithIcon {...otherProps} actionCallback={handleSaveAndReRoute} />;
};

interface ISubmitButton extends IActionButtonProps {
    setSuccessMessage: (successMessage: string) => void;
    setErrorMessage: (errorMessage: string) => void;
    topOfPageRef: MutableRefObject<HTMLDivElement>;
}

export const SubmitButton = (props: ISubmitButton) => {
    const { setSuccessMessage, setErrorMessage, topOfPageRef, ...otherProps } = props;
    const postStudentData = usePostStudentData(setSuccessMessage);

    const { clientSnapshot, setClientSnapshot } = useClientSnapshot();
    const { studentAnswers } = useStudentAnswers();
    const postWebStatus = usePostWebStatus(setErrorMessage);
    const navigate = useNavigate();

    const trackValidationWebStatus = async () => {
        if (clientSnapshot.web_status === StudentWebStatusEnum.started) {
            setClientSnapshot(prevValue => ({ ...prevValue, web_status: StudentWebStatusEnum.validating }));
            try {
                await postWebStatus.mutateAsync(StudentWebStatusEnum.validating);
            } catch {}
        }
    };

    const canUserSubmit = async () => {
        const validationErrors = await getAllYupErrors(completeSchema, studentAnswers);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmission = async () => {
        setClientSnapshot(prevValue => ({ ...prevValue, web_status: StudentWebStatusEnum.submitted }));
        try {
            await postWebStatus.mutateAsync(StudentWebStatusEnum.submitted, {
                onSuccess: () => {
                    navigate('/completion');
                },
            });
        } catch {}
    };

    const submitCallback = async () => {
        if (!clientSnapshot.is_logged_in) {
            navigate('/signup');
            return;
        }
        try {
            await postStudentData.mutateAsync(studentAnswers);
        } catch {}

        await trackValidationWebStatus();

        const canSubmit = await canUserSubmit();

        if (canSubmit) {
            await handleSubmission();
        } else {
            navigate('/');
            topOfPageRef.current?.scrollIntoView();
        }
    };

    return (
        <ButtonWithIcon
            {...otherProps}
            actionCallback={submitCallback}
            iuVariant={ThemeVariant.white}
            loadingVariantSize="s"
            isLoading={postStudentData.isLoading || postWebStatus.isLoading}
        />
    );
};

interface INavButtonProps extends IActionButtonProps {
    iuTargetRoute: string;
    topOfPageRef: MutableRefObject<HTMLDivElement | null>;
}

export const NavButton = (props: INavButtonProps) => {
    const { iuTargetRoute, topOfPageRef, ...otherProps } = props;
    const navigate = useNavigate();
    return (
        <ButtonWithIcon
            {...otherProps}
            actionCallback={() => {
                navigate(iuTargetRoute);
                (topOfPageRef?.current || { scrollIntoView: () => {} }).scrollIntoView();
            }}
        />
    );
};
