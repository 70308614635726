import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import './FormValidationError.scss';

interface IValidationError<T extends SupportedComplexObjs> {
    yupValueKey: keyof T;
    yupError: string[];
}

// FIXME expose styling
const ValidationError = <T extends SupportedComplexObjs>(props: IValidationError<T>) => {
    const { yupValueKey, yupError } = props;

    const deduplicated = [...new Set(yupError)];

    return (
        <div id={`${yupValueKey.toString()}-error`} className={yupError.length > 0 ? 'ValidationError' : ''}>
            {yupError.length > 0
                ? deduplicated.map((error, index) => (
                      <p key={`${yupValueKey.toString()}-error-${index}`} className="ValidationError__text">
                          {error}
                      </p>
                  ))
                : undefined}
        </div>
    );
};

export default ValidationError;
