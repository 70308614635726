import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import ValidationError from '../FormValidationError/FormValidationError';
import { Label } from '@radix-ui/react-label';
import FormStyledInput from '../FormStyledInput/FormStyledInput';
import { SupportedInputTypes, TextInputVariant } from '../../../interfaces/VariationTypes';
import { IFormComponentProps, primitives } from '../../../interfaces/StudentProfileTypes';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import React from 'react';

interface ITextInputProps<T extends SupportedComplexObjs> {
    iuLabel: string;
    setValues: (value: React.SetStateAction<T>) => void;
    yupValueKey: keyof T;
    value: primitives;
    yupError: string[];
    iuCustomStyle?: ICustomStyle[];
    iuCharacterLimit?: number;
    iuVariant?: TextInputVariant;
    iuAutoComplete?: string;
}

export const TextInputWithoutMemo = <T extends SupportedComplexObjs>(props: ITextInputProps<T>) => {
    const { iuLabel, setValues, yupValueKey, value, iuCustomStyle, iuVariant, iuAutoComplete, yupError, ...otherProps } = props;
    const valueKey = yupValueKey.toString();

    const classNames = {
        BasicForm: 'BasicForm',
        BasicForm__question: 'BasicForm__question',
        BasicForm__textAnswer: 'BasicForm__textAnswer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.BasicForm}>
            <Label id={valueKey} className={newClassNames.BasicForm__question}>
                {iuLabel}
            </Label>
            <div>
                <FormStyledInput
                    value={value}
                    aria-labelledby={valueKey}
                    aria-invalid={yupError.length > 0}
                    onChange={event => setValues(prevValue => ({ ...prevValue, [yupValueKey]: event?.target.value }))}
                    className={newClassNames.BasicForm__textAnswer}
                    iuVariant={iuVariant}
                    aria-describedby={`${valueKey}-error`}
                    iuCustomStyle={iuCustomStyle}
                    inputType={SupportedInputTypes.text}
                    iuAutoComplete={iuAutoComplete}
                    {...otherProps}
                />
                <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};

export const TextInput = React.memo(TextInputWithoutMemo) as typeof TextInputWithoutMemo;

export const FormTextInput = (props: IFormComponentProps) => {
    const { yupValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return <TextInput {...otherProps} value={studentAnswers[yupValueKey]} {...validity} yupValueKey={yupValueKey} setValues={setStudentAnswers} />;
};
