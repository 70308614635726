import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ObjectSchema } from 'yup';
import { formatErrorMessage } from '../../api/statusHandler';
import { formatClassNames, getAllYupErrors } from '../../configBuilder/configUtil';
import { SignUpErrors, PasswordResetErrors, SignUpDataObj, PasswordResetObj } from '../../interfaces/AccountDataTypes';
import { CustomUseMutationType } from '../../interfaces/CustomReactQueryTypes';
import { ICustomStyle } from '../../interfaces/CustomStyleTypes';
import { SupportedComplexObjs, SupportedGenericErrors } from '../../interfaces/SharedTypes';
import { SeverityVariant } from '../../interfaces/VariationTypes';
import ButtonWithIcon from '../BasicUtil/Buttons/ButtonWithIcon';
import MaterialIcon from '../BasicUtil/MaterialIcon';
import BasicToast from '../UserFeedback/BasicToast/BasicToast';
import './SharedAccount.scss';

interface IAccountCardWrapperProps {
    children: JSX.Element | JSX.Element[];
    iuCustomStyle?: ICustomStyle[];
}

export const AccountCardWrapper = (props: IAccountCardWrapperProps) => {
    const { children, iuCustomStyle } = props;

    const classNames = {
        SharedAccount__card: 'SharedAccount__card',
        SharedAccount: 'SharedAccount',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.SharedAccount}>
            <div className={newClassNames.SharedAccount__card}>{children}</div>
        </div>
    );
};

interface IBasicAuthBaseProps<T extends SupportedComplexObjs> {
    setSuccessMessage: (successMessage: string) => void;
    iuCustomStyle?: ICustomStyle[];
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    accountValues: T;
    setAccountValues: (accountValues: React.SetStateAction<T>) => void;
    emptyAccountErrors: SupportedGenericErrors<T>;
    yupSchema: ObjectSchema<any>;
    yupErrors: SupportedGenericErrors<T>;
    setYupErrors: (yupErrors: React.SetStateAction<SupportedGenericErrors<T>>) => void;
    mutationHandler: CustomUseMutationType<T>;
    children: JSX.Element[] | JSX.Element;
}

enum SupportedPaths {
    login = '/login',
    signup = '/signup',
    confirm = '/confirm',
    forgot = '/forgot',
    reset = '/reset',
}

const getPathStaticInfo = (path: SupportedPaths[keyof SupportedPaths], isSubmittingChanges: boolean) => {
    let pathInfo;
    switch (path) {
        case SupportedPaths.login:
            pathInfo = { fieldsToCheck: ['username', 'password'], title: 'Log In', className: 'LoginCard' };
            break;
        case SupportedPaths.signup:
            pathInfo = { fieldsToCheck: ['username', 'password'], title: 'Sign Up', className: 'SignUpCard' };
            break;
        case SupportedPaths.confirm:
            pathInfo = { fieldsToCheck: ['code'], title: 'Confirm Your Account', className: 'ConfirmCard' };
            break;
        case SupportedPaths.forgot:
            pathInfo = { fieldsToCheck: ['username'], title: 'Forgot Password', className: 'ForgotPassword' };
            break;
        default:
            pathInfo = { fieldsToCheck: ['password', 'confirm_password', 'id'], title: 'Reset Password', className: 'ResetPassword' };
            break;
    }
    if (!isSubmittingChanges && path === SupportedPaths.login) pathInfo.fieldsToCheck = [];
    else if (!isSubmittingChanges) pathInfo.fieldsToCheck = ['password'];
    return pathInfo;
};

export const BasicAuthBase = <T extends SupportedComplexObjs>(props: IBasicAuthBaseProps<T>) => {
    const {
        setSuccessMessage,
        iuCustomStyle,
        accountValues,
        setAccountValues,
        yupSchema,
        yupErrors,
        setYupErrors,
        emptyAccountErrors,
        mutationHandler,
        children,
        ...otherProps
    } = props;
    const location = useLocation();
    const pathInfo = getPathStaticInfo(location.pathname, true);

    const hasYupErrors = async (isSubmittingChanges: boolean) => {
        const fieldsToCheck = getPathStaticInfo(location.pathname, isSubmittingChanges).fieldsToCheck;
        const errors = await getAllYupErrors(yupSchema, accountValues);
        const filteredErrors = Object.entries(errors).filter(item => fieldsToCheck.includes(item[0]));
        setYupErrors({ ...emptyAccountErrors, ...Object.fromEntries(filteredErrors) });
        return Object.keys(filteredErrors).length > 0;
    };

    useEffect(() => {
        hasYupErrors(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountValues]);

    const className = pathInfo.className;

    const classNames = {
        [className]: className,
        [`${className}__buttonContainer`]: `${className}__buttonContainer`,
        [`${className}__headerContainer`]: `${className}__headerContainer`,
        [`${className}__header`]: `${className}__header`,
        [`${className}__inputContainer`]: `${className}__inputContainer`,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <div className={newClassNames[className]}>
            <div className={newClassNames[`${className}__inputContainer`]}>
                <div className={newClassNames[`${className}__headerContainer`]}>
                    <h2 className={newClassNames[`${className}__header`]}>{pathInfo.title}</h2>
                </div>
                <BasicToast
                    hasClose={false}
                    message={mutationHandler.error ? formatErrorMessage(mutationHandler.error) : ''}
                    theme={SeverityVariant.error}
                    iuCustomStyle={iuCustomStyle}
                />
                {children}
            </div>
            <div className={newClassNames[`${className}__buttonContainer`]}>
                <BasicAuthButton
                    iuCustomStyle={iuCustomStyle}
                    handleValidations={hasYupErrors}
                    accountValues={accountValues}
                    mutationHandler={mutationHandler}
                    className={className}
                />
                <FooterLinks {...otherProps} />
            </div>
        </div>
    );
};

interface IBasicAuthButtonProps<T extends SupportedComplexObjs> {
    handleValidations: (isSubmit: boolean) => Promise<boolean>;
    accountValues: T;
    iuCustomStyle?: ICustomStyle[];
    mutationHandler: CustomUseMutationType<T>;
    className: string;
}

export const BasicAuthButton = <T extends SupportedComplexObjs>(props: IBasicAuthButtonProps<T>) => {
    const { handleValidations, accountValues, iuCustomStyle, mutationHandler, className } = props;

    const actionCallback = async () => {
        const hasErrors = await handleValidations(true);
        if (!hasErrors) {
            mutationHandler.mutate(accountValues);
        }
    };
    const classNames = {
        [`${className}__submitButton`]: `${className}__submitButton`,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <ButtonWithIcon
            className={newClassNames[`${className}__submitButton`]}
            iuButtonText="Submit"
            iuCustomStyle={iuCustomStyle}
            actionCallback={actionCallback}
            isLoading={mutationHandler.isLoading}
        />
    );
};

interface IFooterLinks {
    iuCustomStyle?: ICustomStyle[];
    footerLink: string;
    footerText: string;
    footerQuestion: string;
}

export const FooterLinks = (props: IFooterLinks) => {
    const { iuCustomStyle, footerLink, footerText, footerQuestion } = props;
    const classNames = {
        SharedAccount__link: 'SharedAccount__link',
        SharedAccount__linkContainer: 'SharedAccount__linkContainer',
        SharedAccount__linkWrapper: 'SharedAccount__linkWrapper',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.SharedAccount__linkContainer}>
            <p className={newClassNames.SharedAccount__linkWrapper}>
                {footerQuestion}{' '}
                <a className={newClassNames.SharedAccount__link} href={footerLink}>
                    {footerText}
                </a>
            </p>
        </div>
    );
};

interface IDisplayPasswordReqs {
    yupErrors: SignUpErrors | PasswordResetErrors;
    signUpValues: SignUpDataObj | PasswordResetObj;
    passwordValidationMessages: object;
    iuCustomStyle?: ICustomStyle[];
}
enum PasswordIcons {
    inactive = 'check_indeterminate_small',
    error = 'error',
    success = 'check_circle',
}

export const PasswordRequirements = (props: IDisplayPasswordReqs) => {
    const { yupErrors, signUpValues, passwordValidationMessages, iuCustomStyle } = props;

    const getPasswordMode = (errorText: string): keyof typeof PasswordIcons => {
        if (signUpValues.password === '') {
            return 'inactive';
        } else if (yupErrors.password?.includes(errorText)) {
            return 'error';
        } else {
            return 'success';
        }
    };

    const classNames = {
        PasswordRequirements: 'PasswordRequirements',
        PasswordRequirements__item: 'PasswordRequirements__item',
        PasswordRequirements__message_success: 'PasswordRequirements__message_success',
        PasswordRequirements__message_error: 'PasswordRequirements__message_error',
        PasswordRequirements__message_inactive: 'PasswordRequirements__message_inactive',
        PasswordRequirements__icon_error: 'PasswordRequirements__icon_error',
        PasswordRequirements__icon_success: 'PasswordRequirements__icon_success',
        PasswordRequirements__icon_inactive: 'PasswordRequirements__icon_inactive',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.PasswordRequirements}>
            {Object.values(passwordValidationMessages).map((errorText, index) => {
                const mode = getPasswordMode(errorText);
                return (
                    <div className={newClassNames.PasswordRequirements__item} key={`password-requirements-${index}`}>
                        <MaterialIcon iconName={PasswordIcons[mode]} className={newClassNames[`PasswordRequirements__icon_${mode}`]} />
                        <p className={newClassNames[`PasswordRequirements__message_${mode}`]}>{errorText}</p>
                    </div>
                );
            })}
        </div>
    );
};
