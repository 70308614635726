import { MutableRefObject, useEffect } from 'react';
import { Routes } from 'react-router-dom';
import BasicDialog from '../UserFeedback/BasicDialog/BasicDialog';
import BasicToast from '../UserFeedback/BasicToast/BasicToast';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePutLastPage } from '../../api/usePutLastPage';
import { useClientSnapshot } from '../../stateManagement/useClientSnapshot';
import { StudentWebStatusEnum } from '../../interfaces/StudentProfileTypes';

interface IAppProps {
    topOfPageRef: MutableRefObject<HTMLDivElement | null>;
    successMessage: string;
    setSuccessMessage: (successMessage: string) => void;
    children: JSX.Element | JSX.Element[];
    errorMessage: string;
    setErrorMessage: (errorMessage: string) => void;
}
export const App = (props: IAppProps) => {
    const { topOfPageRef, successMessage, setSuccessMessage, errorMessage, setErrorMessage, children } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const { clientSnapshot } = useClientSnapshot();
    const putLastPage = usePutLastPage();

    useEffect(() => {
        const isSubmitted = clientSnapshot.web_status === StudentWebStatusEnum.submitted && location.pathname !== '/completion';
        const shouldUpdateLastPage = clientSnapshot.web_status === StudentWebStatusEnum.started || clientSnapshot.web_status === StudentWebStatusEnum.validating;
        if (isSubmitted) {
            navigate('/completion');
        } else if (shouldUpdateLastPage) {
            putLastPage.mutate(location.pathname);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, clientSnapshot.is_logged_in, clientSnapshot.web_status]);

    return (
        <form onSubmit={event => event.preventDefault()}>
            <div ref={topOfPageRef} className="App" role="main">
                <Routes>{children}</Routes>
            </div>
            {successMessage ? <BasicToast setMessage={setSuccessMessage} message={successMessage} hasClose /> : undefined}
            {errorMessage ? (
                <BasicDialog handleClose={() => setErrorMessage('')}>
                    <>{errorMessage}</>
                </BasicDialog>
            ) : undefined}
        </form>
    );
};
