import { evaluateConditionals } from '../../../configBuilder/configUtil';
import { IConditionObj } from '../../../interfaces/ConditionTypes';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { ClientSnapshot } from '../../../interfaces/StudentProfileTypes';
import { useClientSnapshot } from '../../../stateManagement/useClientSnapshot';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';

interface IConditionalDisplayProps<T extends SupportedComplexObjs> {
    iuConditionObj: IConditionObj<T>;
    renderProps: {
        truthyChild: JSX.Element | JSX.Element[];
        falsyChild: JSX.Element | JSX.Element[];
    };
    values: T;
}
export const ConditionalDisplay = <T extends SupportedComplexObjs>(props: IConditionalDisplayProps<T>) => {
    const { iuConditionObj, renderProps, values } = props;

    const conditionIsTruthy = evaluateConditionals(iuConditionObj, values);
    return <>{conditionIsTruthy ? renderProps.truthyChild : renderProps.falsyChild}</>;
};

export const StudentAnswersConditional = (props: IConditionalDisplayProps<StudentAnswersObj>) => {
    const { studentAnswers } = useStudentAnswers();
    return <ConditionalDisplay {...props} values={studentAnswers} />;
};

export const ClientSnapshotConditional = (props: IConditionalDisplayProps<ClientSnapshot>) => {
    const { clientSnapshot } = useClientSnapshot();
    return <ConditionalDisplay {...props} values={clientSnapshot as ClientSnapshot} />;
};

export const NoContent = () => <></>;
