import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IApiWebStatus, IClientSnapshot, IProfileObj, StudentWebStatusEnum } from '../interfaces/StudentProfileTypes';
import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { formatErrorMessage, statusHandler } from './statusHandler';
import { usePostWebStatus } from './usePostWebStatus';

export const useGetStudentProfile = (
    setStudentAnswers: (studentAnswers: React.SetStateAction<StudentAnswersObj>) => void,
    setClientSnapshot: (clientSnapshot: React.SetStateAction<IClientSnapshot>) => void,
    isUserLoggedIn: boolean,
    setErrorMessage: (errorMessage: string) => void
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const acsPrefillToken = searchParams.get('token');
    useEffect(() => {
        if (acsPrefillToken) {
            fetchAcsPrefillData(setStudentAnswers, acsPrefillToken, setErrorMessage, setIsLoading);
        }
        setSearchParams('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acsPrefillToken]);

    const postWebStatus = usePostWebStatus(setErrorMessage);
    const authedStudentProfile = useQuery<IProfileObj, Error>('student-profile', () => statusHandler(fetch, '/api/profile', 'GET'), {
        ...defaultReactQueryOptions,
        onSuccess: data => {
            // add in answers, keep any values from preview or ACS prefill, authed data takes precedence
            setStudentAnswers(prevValue => ({ ...prevValue, ...data.answer }));
            const currentWebStatus = getCurrentWebStatus(data.web_status);

            // if we don't have a webstatus yet, go ahead and update the API
            if (currentWebStatus === null) postWebStatus.mutate(StudentWebStatusEnum.started);

            // update client snapshot with API results for web status
            setClientSnapshot(prevValue => ({ ...prevValue, web_status: currentWebStatus ?? StudentWebStatusEnum.started, username: data.username }));

            data.last_page && navigate(data.last_page);
        },
        enabled: isUserLoggedIn,
        onError: error => setErrorMessage(formatErrorMessage(error)),
    });

    return { ...authedStudentProfile, isLoading: authedStudentProfile.isLoading || isLoading };
};

export const fetchAcsPrefillData = async (
    setStudentAnswers: (studentAnswers: React.SetStateAction<StudentAnswersObj>) => void,
    acsPrefillToken: string,
    setErrorMessage: (errorModalMessage: string) => void,
    setIsLoading: (isLoading: boolean) => void
) => {
    try {
        setIsLoading(true);
        const acsStudentData = await statusHandler(fetch, `/public/tmp?token=${acsPrefillToken}`, 'GET');

        setStudentAnswers(prevValue => ({ ...acsStudentData, ...prevValue }));
    } catch (err: any) {
        setErrorMessage(err.message);
    }
    setIsLoading(false);
};

export const getCurrentWebStatus = (webStatuses: IApiWebStatus[]) => {
    // slight paranoia on the following lines. Neither _should_ be possible, but it would be quite bad for the user if assigned an incorrect status
    const deduplicated = [...new Set(webStatuses)];
    const validStatuses = deduplicated.filter(status => status.web_status in StudentWebStatusEnum);

    const mapLenToStatus = {
        0: null, // signifies the API needs to be notified for started
        1: StudentWebStatusEnum.started,
        2: StudentWebStatusEnum.validating,
        3: StudentWebStatusEnum.submitted,
    } as Record<number, StudentWebStatusEnum | null>;
    return mapLenToStatus[validStatuses.length];
};
