import { useState } from 'react';
import { usePostForgotPassword } from '../../../api/usePostForgotPassword';
import { passwordResetSchema } from '../../../configBuilder/devYupSchemas';
import { PasswordResetObj, PasswordResetErrors, emptyPasswordResetObj, emptyPasswordResetErrors } from '../../../interfaces/AccountDataTypes';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { TextInput } from '../../BasicForm/FormTextInput/FormTextInput';
import { BasicAuthBase } from '../SharedAccount';

interface IForgotPasswordCard {
    setSuccessMessage: (successMessage: string) => void;
    iuCustomStyle?: ICustomStyle[];
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    iuVariant?: TextInputVariant;
}

export const ForgotPasswordCard = (props: IForgotPasswordCard) => {
    const { setSuccessMessage, iuCustomStyle, iuVariant, ...otherProps } = props;
    const [passwordResetValues, setPasswordResetValues] = useState<PasswordResetObj>(emptyPasswordResetObj);
    const [yupErrors, setYupErrors] = useState<PasswordResetErrors>(emptyPasswordResetErrors);

    const postForgotPassword = usePostForgotPassword(setSuccessMessage);

    return (
        <BasicAuthBase
            accountValues={passwordResetValues}
            setAccountValues={setPasswordResetValues}
            emptyAccountErrors={emptyPasswordResetErrors}
            yupSchema={passwordResetSchema}
            yupErrors={yupErrors}
            setYupErrors={setYupErrors}
            mutationHandler={postForgotPassword}
            setSuccessMessage={setSuccessMessage}
            iuCustomStyle={iuCustomStyle}
            {...otherProps}
        >
            <TextInput<PasswordResetObj>
                iuLabel="Email Address"
                setValues={setPasswordResetValues}
                yupValueKey="username"
                value={passwordResetValues.username}
                yupError={yupErrors.username}
                iuCustomStyle={iuCustomStyle}
                iuAutoComplete="username"
                iuVariant={iuVariant}
            />
        </BasicAuthBase>
    );
};
