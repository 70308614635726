import { string, object, AnySchema, ref } from 'yup';
import { SignUpDataObj, LoginDataObj, PasswordResetObj, ConfirmObj } from '../interfaces/AccountDataTypes';

export const passwordValidationMessages = {
    len: 'is at least 12 characters',
    upper: 'contains at least one uppercase letter',
    lower: 'contains at least one lowercase letter',
    number: 'contains at least one number',
    special: 'contains at least one special character',
};

export const passwordResetMessages = {
    ...passwordValidationMessages,
    matching: 'passwords must match',
};

export const signUpSchema = object().shape<Record<keyof SignUpDataObj, AnySchema>>({
    username: string().matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Your email address must be valid.'),
    password: string()
        .min(12, passwordValidationMessages.len)
        .matches(/(?=.*[A-Z])/, passwordValidationMessages.upper)
        .matches(/(?=.*[a-z])/, passwordValidationMessages.lower)
        .matches(/(?=.*[0-9])/, passwordValidationMessages.number)
        .matches(/[~!@#&;:><$%^*_,"`'|=+.?\][(){}/-]+/, passwordValidationMessages.special),
});

export const confirmSchema = object().shape<Record<keyof ConfirmObj, AnySchema>>({
    username: string().matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Your email address must be valid.'),
    code: string()
        .min(6, 'The confirmation code must be exactly 6 numbers.')
        .max(6, 'The confirmation code must be exactly 6 numbers.')
        .matches(/^\d+$/, 'The confirmation code must be exactly 6 numbers.'),
});

export const loginSchema = object().shape<Record<keyof LoginDataObj, AnySchema>>({
    username: string().matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Please provide a valid email address.'),
    password: string().required('Password is required.'),
});

export const passwordResetSchema = object().shape<Record<keyof PasswordResetObj, AnySchema>>({
    username: string().matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Your email address must be valid.'),
    password: string()
        .min(12, passwordResetMessages.len)
        .matches(/(?=.*[A-Z])/, passwordResetMessages.upper)
        .matches(/(?=.*[a-z])/, passwordResetMessages.lower)
        .matches(/(?=.*[0-9])/, passwordResetMessages.number)
        .matches(/[~!@#&;:><$%^*_,"`'|=+.?\][(){}/-]+/, passwordResetMessages.special)
        .oneOf([ref('confirm_password')], passwordResetMessages.matching),
    confirm_password: string(),
    id: string(),
});
