import { keyframes } from '@stitches/react';
import './LoadingSpinner.scss';

// FIXME - color system story to go wide
const animateStroke = keyframes({
    '0%': {
        strokeDasharray: '1, 200',
        strokeDashoffset: 0,
        stroke: '#002449',
    },
    '50%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: -35,
        stroke: '#3f8cca',
    },
    '100%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: -124,
        stroke: '#3f8cca',
    },
});

const rotate = keyframes({
    '100%': {
        transform: 'rotate(360deg)',
    },
});

export const LoadingSpinner = () => {
    return (
        <div className="LoadingSpinner">
            <svg
                style={{
                    animation: `${rotate} 2s ease-in-out infinite`,
                }}
                aria-busy="true"
                aria-live="polite"
                aria-label="loading spinner"
                className="LoadingSpinner__spinner"
                viewBox="25 25 50 50"
            >
                <circle
                    style={{
                        animation: `${animateStroke} 1.5s linear infinite`,
                    }}
                    className="LoadingSpinner__path"
                    cx="50"
                    cy="50"
                    r="20"
                />
            </svg>
        </div>
    );
};
