import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { SignUpDataObj } from '../interfaces/AccountDataTypes';
import { useNavigate } from 'react-router-dom';

export const usePostSignUpData = (setSuccessMessage: (successMessage: string) => void, values: SignUpDataObj) => {
    const navigate = useNavigate();
    return useMutation((values: SignUpDataObj) => statusHandler(fetch, '/public/signup', 'POST', values), {
        ...defaultReactQueryOptions,
        onSuccess: (apiResp: { message: string }) => {
            setSuccessMessage(apiResp.message);
            navigate('/confirm', { state: { username: values.username } });
        },
        onError: () => {},
    });
};
