export const statusHandler = async (fetchCallback: (apiUri: string, options: any) => any, apiUri: string, method: string, payload?: object, cookie?: string) => {
    const contentTypeHeader = { 'Content-Type': 'application/json' };
    const requestHeaders = cookie
        ? {
              ...contentTypeHeader,
              Cookie: cookie,
          }
        : contentTypeHeader;
    const options: any = {
        method,
        headers: requestHeaders,
    };
    if (payload) options.body = JSON.stringify(payload);

    const response = await fetchCallback(apiUri, options);
    if (response.status !== 200) {
        const result = await response.json();
        throw new Error(formatErrorMessage(result));
    } else {
        return response.json();
    }
};

export const formatErrorMessage = (result: any) => {
    console.error(JSON.stringify(result, Object.getOwnPropertyNames(result)));
    if (result && typeof result === 'object' && 'message' in result && typeof result.message == 'string') {
        return result.message;
    }
    return "We've encountered an unexpected error at this time. Please try again later.";
};
