import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { useNavigate } from 'react-router-dom';

export const usePostResendConfirmationCode = (setSuccessMessage: (successMessage: string) => void, username: string) => {
    const navigate = useNavigate();
    return useMutation((username: string) => statusHandler(fetch, '/public/resend-confirm', 'POST', { username }), {
        ...defaultReactQueryOptions,
        onSuccess: (apiResp: { message: string }) => {
            setSuccessMessage(apiResp.message);
            navigate('/confirm', { state: { username: username } });
        },
        onError: () => {},
    });
};
