import { ChangeEventHandler } from 'react';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import './FormStyledInput.scss';
import { SupportedInputTypes, TextInputVariant } from '../../../interfaces/VariationTypes';
import { primitives } from '../../../interfaces/StudentProfileTypes';

interface IFormStyledInputProps {
    inputType: keyof typeof SupportedInputTypes;
    iuVariant?: TextInputVariant;
    value?: primitives;
    'aria-labelledby': string;
    'aria-invalid': boolean;
    iuAutoComplete?: string;
    className?: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur?: ChangeEventHandler<HTMLInputElement>;
    iuCustomStyle?: ICustomStyle[];
    iuCharacterLimit?: number;
    required?: boolean;
}

const FormStyledInput = (props: IFormStyledInputProps) => {
    const { iuVariant = 'outlined', iuCustomStyle, value, inputType, iuAutoComplete: autoComplete, iuCharacterLimit, ...otherProps } = props;

    // NOTE: classnames do not conform to BEM capitalization principals to make use of the iuVariant.
    const classNames = {
        [`${iuVariant}__input`]: `${iuVariant}__input`,
        [iuVariant]: `${iuVariant}`,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <div className={newClassNames[iuVariant]}>
            <input
                {...otherProps}
                autoComplete={autoComplete}
                type={inputType}
                className={newClassNames[`${iuVariant}__input`]}
                value={value?.toString() || ''}
                maxLength={iuCharacterLimit || 45}
            />
        </div>
    );
};

export default FormStyledInput;
