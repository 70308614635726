import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import ValidationError from '../FormValidationError/FormValidationError';
import './FormSingleCheckInput.scss';
import { Label } from '@radix-ui/react-label';
import { Checkbox, CheckboxIndicator } from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import React from 'react';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';

interface ISingleCheckInputProps<T extends SupportedComplexObjs> {
    iuLabel: string;
    setValues: (value: React.SetStateAction<T>) => void;
    yupValueKey: keyof T;
    yupError: string[];
    value?: boolean;
    iuCustomStyle?: ICustomStyle[];
}

export const SingleCheckInput = <T extends SupportedComplexObjs>(props: ISingleCheckInputProps<T>) => {
    const { iuLabel, setValues, yupValueKey, value, yupError, iuCustomStyle } = props;
    const valueKey = yupValueKey.toString();

    const classNames = {
        SingleCheck: 'SingleCheck',
        SingleCheck__label: 'SingleCheck__label',
        SingleCheck__labelContainer: 'SingleCheck__labelContainer',
        SingleCheck__check: 'SingleCheck__check',
        SingleCheck__input: 'SingleCheck__input',
        SingleCheck__input_check: 'SingleCheck__input_check',
        SingleCheck__icon: 'SingleCheck__icon',
        SingleCheck__indicatorContainer: 'SingleCheck__indicatorContainer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.SingleCheck}>
            <Checkbox
                checked={!!value}
                className={`${newClassNames.SingleCheck__check} ${value ? newClassNames.SingleCheck__input_check : newClassNames.SingleCheck__input}`}
                aria-labelledby={valueKey}
                aria-invalid={yupError.length > 0}
                onCheckedChange={() => setValues(prevValue => ({ ...prevValue, [valueKey]: !value }))}
                aria-describedby={`${valueKey}-error`}
            >
                <CheckboxIndicator className={newClassNames.SingleCheck__indicatorContainer}>
                    {value ? <CheckIcon className={newClassNames.SingleCheck__icon} /> : undefined}
                </CheckboxIndicator>
            </Checkbox>
            <div className={newClassNames.SingleCheck__labelContainer}>
                <Label id={valueKey} className={newClassNames.SingleCheck__label}>
                    {iuLabel}
                </Label>
                <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};

interface IFormSingleCheckInput {
    yupValueKey: keyof StudentAnswersObj;
    iuLabel: string;
}
export const FormSingleCheckInput = (props: IFormSingleCheckInput) => {
    const { yupValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return <SingleCheckInput {...otherProps} value={!!studentAnswers[yupValueKey]} {...validity} yupValueKey={yupValueKey} setValues={setStudentAnswers} />;
};
