import './BasicDialog.scss';
import { DialogContent, DialogTitle, DialogDescription, DialogClose, DialogOverlay, Dialog, DialogPortal, DialogTrigger } from '@radix-ui/react-dialog';
import { styled, keyframes } from '@stitches/react';
import MaterialIcon from '../../BasicUtil/MaterialIcon';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { ModalSizeVariant, ModalSizes, TabletModalSizes } from '../../../interfaces/VariationTypes';

interface IBaseModalProps {
    children: JSX.Element | JSX.Element[];
    iuCustomStyle?: ICustomStyle[];
    iuModalHeader?: string;
    size?: ModalSizeVariant;
}

//
// information modal
interface IBasicDialogProps extends IBaseModalProps {
    handleClose: () => void;
}

const BasicDialog = (props: IBasicDialogProps) => {
    return (
        <Dialog open>
            <ModalContent {...props} />
        </Dialog>
    );
};

export default BasicDialog;

//
// modal button
interface IModalButtonProps extends IBaseModalProps {
    iuButtonText: string;
    iuLeadingIcon?: string;
    iuTrailingIcon?: string;
    yupMetaKey: string;
}

export const ModalButton = (props: IModalButtonProps) => {
    const { iuButtonText, yupMetaKey, children, iuCustomStyle, ...otherProps } = props;

    const classNames = {
        Dialog__trigger: 'Dialog__trigger',
        Dialog__triggerContent: 'Dialog__triggerContent',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <Dialog>
            <DialogTrigger className={newClassNames.Dialog__trigger}>
                <span className={newClassNames.Dialog__triggerContent}>{iuButtonText}</span>
            </DialogTrigger>
            <ModalContent {...otherProps} handleClose={() => {}} iuCustomStyle={iuCustomStyle}>
                {children}
            </ModalContent>
        </Dialog>
    );
};

//
// internal shared helper components

// css animations
const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 0.5 },
});

const contentShow = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

// styled overlay
export const StyledOverlay = styled(DialogOverlay, {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'fixed',
    inset: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

// sized content
const getStyledDialogContent = (size: ModalSizeVariant) => {
    return styled(DialogContent, {
        width: ModalSizes[size],
        '@media screen and (max-width:545px)': {
            width: '90vw',
            height: '100%',
            overflowY: 'scroll',
        },
        '@media screen and (max-width: 1023px) and (min-width: 546px)': {
            width: TabletModalSizes[size],
            height: 'auto',
            maxHeight: '100%',
            overflowY: 'scroll',
        },
        '@media (prefers-reduced-motion: no-preference)': {
            animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        },
    });
};

const ModalContent = (props: IBasicDialogProps) => {
    const { handleClose, iuCustomStyle, children, iuModalHeader = 'Error', size = 'xs' } = props;

    const classNames = {
        Dialog: 'Dialog',
        Dialog__header: 'Dialog__header',
        Dialog__headerContainer: 'Dialog__headerContainer',
        Dialog__closeTrigger: 'Dialog__closeTrigger',
        Dialog__closeIcon: 'Dialog__closeIcon',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    const StyledDialogContent = getStyledDialogContent(size);
    return (
        <DialogPortal>
            <StyledOverlay>
                <StyledDialogContent className={newClassNames.Dialog}>
                    <div className={newClassNames.Dialog__headerContainer}>
                        <DialogTitle className={newClassNames.Dialog__header}>{iuModalHeader}</DialogTitle>
                        <DialogClose className={newClassNames.Dialog__closeTrigger} onClick={handleClose}>
                            <MaterialIcon className={newClassNames.Dialog__closeIcon} iconName="close" />
                        </DialogClose>
                    </div>
                    <DialogDescription>{children}</DialogDescription>
                </StyledDialogContent>
            </StyledOverlay>
        </DialogPortal>
    );
};
