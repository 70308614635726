import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';

export const usePostStudentData = (setSuccessMessage: (successMessage: string) => void) => {
    return useMutation('studentAnswers', (studentAnswers: StudentAnswersObj) => statusHandler(fetch, '/api/answer', 'PUT', studentAnswers), {
        ...defaultReactQueryOptions,
        onSuccess: (message: { message: string }) => setSuccessMessage(message.message),
    });
};
