import { useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import { useClientSnapshot } from './useClientSnapshot';
import { SupportedComplexObjs } from '../interfaces/SharedTypes';
import { StudentWebStatusEnum } from '../interfaces/StudentProfileTypes';
import { useStudentAnswers } from './useStudentAnswers';
import { completeSchema } from '../tenantConfig/dataModel/yupSchemas';
import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';

export const useGetAnswersValidity = (yupValueKey: keyof StudentAnswersObj) => {
    const { clientSnapshot } = useClientSnapshot();
    const { studentAnswers } = useStudentAnswers();
    const [yupError, setYupError] = useState<string[]>([]);

    useEffect(() => {
        if (clientSnapshot.web_status === StudentWebStatusEnum.validating) {
            const freshYupError = getYupErrorSync(completeSchema, yupValueKey, studentAnswers);
            setYupError(freshYupError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentAnswers, clientSnapshot.web_status]);

    return { yupError };
};

export const getYupErrorSync = <T extends SupportedComplexObjs>(schema: ObjectSchema<any>, valueKey: keyof T, values: T) => {
    try {
        schema.validateSyncAt(valueKey.toString(), values, { abortEarly: false });
    } catch (err: any) {
        return err.errors || [];
    }
    return [];
};
