export type TextInputVariant = 'underlined' | 'outlined';
export enum SupportedInputTypes {
    text = 'text',
    textarea = 'textarea',
    date = 'date',
    password = 'password',
}

export enum ModalSizes {
    xs = '30rem',
    s = '60rem',
    m = '80vw',
}

export enum TabletModalSizes {
    xs = '30rem',
    s = '70vw',
    m = '90vw',
}

export enum Orientations {
    horizontal,
    vertical,
}

export enum SeparatorWeights {
    thin = '1px',
    regular = '2px',
    bold = '4px',
}

export enum LoadingSizes {
    xs = '0.4rem',
    s = '0.8rem',
    m = '2rem',
}

export enum ThemeVariant {
    primary = 'primary',
    primaryDark = 'primaryDark',
    secondary = 'secondary',
    dark = 'dark',
    accent = 'accent',
    info = 'info',
    white = 'white',
}

export enum SeverityVariant {
    success = 'success',
    error = 'error',
}

export type ModalSizeVariant = keyof typeof ModalSizes;
export type SeparatorOrientations = keyof typeof Orientations;
export type SeparatorWeightVariants = keyof typeof SeparatorWeights;
