import { NavigationMenu, NavigationMenuList, NavigationMenuItem } from '@radix-ui/react-navigation-menu';
import { NavLink } from 'react-router-dom';
import './HorizontalNav.scss';
import React from 'react';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';

interface IHorizontalNavProps {
    children: JSX.Element[];
    iuCustomStyle?: ICustomStyle[];
}

export const HorizontalNav = (props: IHorizontalNavProps) => {
    const { children, iuCustomStyle } = props;

    const classNames = {
        HorizontalNav__block: 'HorizontalNav__block',
        HorizontalNav: 'HorizontalNav',
        HorizontalNav__menuItem: 'HorizontalNav__menuItem',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <NavigationMenu className={newClassNames.HorizontalNav__block}>
            <NavigationMenuList className={newClassNames.HorizontalNav}>
                {children.map((child, index) => (
                    <React.Fragment key={`horiz-nav-${index}`}>
                        <NavigationMenuItem className={newClassNames.HorizontalNav__menuItem}>{child}</NavigationMenuItem>
                    </React.Fragment>
                ))}
            </NavigationMenuList>
        </NavigationMenu>
    );
};

interface IHorizontalNavMenuItemProps {
    iuLeadingIcon?: string;
    iuTrailingIcon?: string;
    iuRoute?: string;
    children: JSX.Element | JSX.Element[] | string;
    iuCustomStyle?: ICustomStyle[];
}

export const HorizontalNavMenuItem = (props: IHorizontalNavMenuItemProps) => {
    const { iuLeadingIcon, iuTrailingIcon, iuRoute, iuCustomStyle, children } = props;

    const classNames = {
        HorizontalNav__link: 'HorizontalNav__link',
        HorizontalNav__link_selected: 'HorizontalNav__link_selected',
        HorizontalNav__icon: 'HorizontalNav__icon',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    if (!iuRoute) return <div className={newClassNames.HorizontalNav__link}>{children}</div>;

    return (
        <NavLink replace className={({ isActive }) => (isActive ? newClassNames.HorizontalNav__link_selected : newClassNames.HorizontalNav__link)} to={iuRoute}>
            {iuLeadingIcon ? <span className={`material-symbols-outlined ${newClassNames.HorizontalNav__icon}`}>{iuLeadingIcon}</span> : undefined}
            <div>{children}</div>
            {iuTrailingIcon ? <span className={`material-symbols-outlined ${newClassNames.HorizontalNav__icon}`}>{iuTrailingIcon}</span> : undefined}
        </NavLink>
    );
};
