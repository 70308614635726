import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

interface IAxsVisuallyHiddenProps {
    children: JSX.Element | JSX.Element[];
}

const AxsVisuallyHidden = (props: IAxsVisuallyHiddenProps) => {
    const { children } = props;
    return <VisuallyHidden data-testid="visually-hidden">{children}</VisuallyHidden>;
};

export default AxsVisuallyHidden;
