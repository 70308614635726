import { formatAddressPrefix, formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { BasicSeparator } from '../../BasicUtil/BasicSeparator/BasicSeparator';
import { FormAutoComplete } from './FormAutoComplete';
import { FormSelectSingle } from '../FormSelect/FormSelect';
import { FormTextInput } from '../FormTextInput/FormTextInput';

export enum AutoCompleteCategoryEnum {
    college_ceeb = 'college_ceeb',
    address = 'address',
}
interface IFormAddressBlock {
    iuCustomStyle?: ICustomStyle[];
    prefix?: string;
    hasSeparator?: boolean;
    iuVariant?: TextInputVariant;
}

export const FormAddressBlock = (props: IFormAddressBlock) => {
    const { iuCustomStyle, prefix = '', hasSeparator, iuVariant } = props;

    const classNames = {
        BasicFormSection: 'BasicFormSection',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    const separator = hasSeparator ? <BasicSeparator orientation="horizontal" iuCustomStyle={iuCustomStyle} /> : undefined;

    return (
        <div className={newClassNames.BasicFormSection}>
            <FormAutoComplete
                iuLabel="Address"
                yupValueKey={formatAddressPrefix(prefix, 'address')}
                iuCustomStyle={iuCustomStyle}
                prefix={prefix}
                category={AutoCompleteCategoryEnum.address}
                yupValueKeys={['address', 'apt_num', 'city', 'state', 'zip', 'country']}
            />
            {separator}
            <FormTextInput iuLabel="Apt. #" yupValueKey={formatAddressPrefix(prefix, 'apt_num')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
            {separator}
            <FormTextInput iuLabel="City" yupValueKey={formatAddressPrefix(prefix, 'city')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
            {separator}
            <FormSelectSingle iuLabel="State/Province" yupValueKey={formatAddressPrefix(prefix, 'state')} iuCustomStyle={iuCustomStyle} dropdownOption="state" />
            {separator}
            <FormTextInput iuLabel="Zip/Postal Code" yupValueKey={formatAddressPrefix(prefix, 'zip')} iuCustomStyle={iuCustomStyle} iuVariant={iuVariant} />
            {separator}
            <FormSelectSingle iuLabel="Country" yupValueKey={formatAddressPrefix(prefix, 'country')} iuCustomStyle={iuCustomStyle} dropdownOption="country" />
        </div>
    );
};
