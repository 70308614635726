import { statusHandler } from './statusHandler';
import { useMutation } from 'react-query';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { PasswordResetObj } from '../interfaces/AccountDataTypes';
import { useNavigate } from 'react-router-dom';

export const usePostResetPassword = (setSuccessMessage: (successMessage: string) => void) => {
    const navigate = useNavigate();
    return useMutation((values: PasswordResetObj) => statusHandler(fetch, '/public/reset-password', 'POST', values), {
        ...defaultReactQueryOptions,
        onSuccess: (apiResp: { message: string }) => {
            setSuccessMessage(apiResp.message);
            navigate('/login');
        },
        onError: () => {},
    });
};
