import { useMutation, useQueryClient } from 'react-query';
import { StudentWebStatusEnum } from '../interfaces/StudentProfileTypes';
import { useClientSnapshot } from '../stateManagement/useClientSnapshot';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { statusHandler } from './statusHandler';

export const usePostWebStatus = (setErrorMessage: (errorMessage: string) => void) => {
    const { clientSnapshot } = useClientSnapshot();
    const queryClient = useQueryClient();
    const postWebStatus = useMutation((web_status: StudentWebStatusEnum) => statusHandler(fetch, '/api/web-status', 'POST', { web_status: web_status }), {
        ...defaultReactQueryOptions,
        onError: () => {
            const verb = getWebStatusVerb(clientSnapshot.web_status);
            setErrorMessage(`There was an error ${verb} your application. Please try again later.`);
            // we couldn't update the API that the user had a new webstatus, invalidate the profile to re-fetch and re-set webstatus.
            queryClient.invalidateQueries('student-profile');
        },
        onSuccess: () => {},
    });
    return postWebStatus;
};

export const getWebStatusVerb = (webStatus: StudentWebStatusEnum | null) => {
    if (webStatus === StudentWebStatusEnum.started || webStatus === null) {
        return 'starting';
    } else if (webStatus === StudentWebStatusEnum.validating) {
        return 'validating';
    } else {
        return 'submitting';
    }
};
