import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePostResetPassword } from '../../../api/usePostResetPassword';
import { PasswordResetObj, PasswordResetErrors, emptyPasswordResetObj, emptyPasswordResetErrors } from '../../../interfaces/AccountDataTypes';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { TextInputVariant } from '../../../interfaces/VariationTypes';
import { BasicAuthBase, PasswordRequirements } from '../SharedAccount';
import { Label } from '@radix-ui/react-label';
import { ToggleVisibility } from '../../BasicUtil/ToggleVisibility/ToggleVisibility';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { passwordResetMessages, passwordResetSchema } from '../../../configBuilder/devYupSchemas';

interface IResetPasswordCard {
    setSuccessMessage: (successMessage: string) => void;
    iuCustomStyle?: ICustomStyle[];
    footerText: string;
    footerLink: string;
    footerQuestion: string;
    iuVariant?: TextInputVariant;
}

export const ResetPasswordCard = (props: IResetPasswordCard) => {
    const { setSuccessMessage, iuCustomStyle, iuVariant, ...otherProps } = props;
    const [passwordResetValues, setPasswordResetValues] = useState<PasswordResetObj>(emptyPasswordResetObj);
    const [yupErrors, setYupErrors] = useState<PasswordResetErrors>(emptyPasswordResetErrors);

    const [searchParams] = useSearchParams();

    const postResetPassword = usePostResetPassword(setSuccessMessage);

    const classNames = {
        ResetPassword__passwordContainer: 'ResetPassword__passwordContainer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    useEffect(() => {
        const username = searchParams.get('username') ?? '';
        const id = searchParams.get('id') ?? '';
        setPasswordResetValues(prevValue => ({ ...prevValue, username, id }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BasicAuthBase
            accountValues={passwordResetValues}
            setAccountValues={setPasswordResetValues}
            emptyAccountErrors={emptyPasswordResetErrors}
            yupSchema={passwordResetSchema}
            yupErrors={yupErrors}
            setYupErrors={setYupErrors}
            mutationHandler={postResetPassword}
            setSuccessMessage={setSuccessMessage}
            iuCustomStyle={iuCustomStyle}
            {...otherProps}
        >
            <div className={newClassNames.ResetPassword__passwordContainer}>
                <Label id="password">New Password</Label>
                <ToggleVisibility<PasswordResetObj>
                    yupValueKey="password"
                    value={passwordResetValues.password}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordResetValues(prevValue => ({ ...prevValue, password: event.target.value }))}
                    iuCustomStyle={iuCustomStyle}
                    iuAutoComplete="new-password"
                    iuVariant={iuVariant}
                    yupError={[]}
                />
                <Label id="confirm_password">Confirm Password</Label>
                <ToggleVisibility<PasswordResetObj>
                    yupValueKey="confirm_password"
                    value={passwordResetValues.confirm_password}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setPasswordResetValues(prevValue => ({ ...prevValue, confirm_password: event.target.value }))
                    }
                    iuCustomStyle={iuCustomStyle}
                    iuAutoComplete="new-password"
                    iuVariant={iuVariant}
                    yupError={[]}
                />
            </div>
            <PasswordRequirements
                yupErrors={yupErrors as PasswordResetErrors}
                signUpValues={passwordResetValues as PasswordResetObj}
                passwordValidationMessages={passwordResetMessages}
                iuCustomStyle={iuCustomStyle}
            />
        </BasicAuthBase>
    );
};
