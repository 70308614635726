import { useState, useRef, useMemo } from 'react';
import '../../tenantConfig/styles/App.scss';
import { App } from './App';
import { useQueryClient } from 'react-query';
import { getDevConfig } from '../../configBuilder/devConfig';
import { getCustomQueryClientOptions } from '../../api/getCustomQueryClientOptions';
import { IRouteObj } from '../../interfaces/ConfigLangTypes';
import { StudentProfileProvider } from '../../stateManagement/useProfileProvider';
import { buildRoutes } from '../../configBuilder/configUtil';

interface IAppProps {
    iuConfigObj: IRouteObj[];
}

const BuildConfigLang = (props: IAppProps) => {
    const { iuConfigObj } = props;

    const topOfPageRef = useRef<HTMLDivElement | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const queryClient = useQueryClient();
    queryClient.setDefaultOptions(getCustomQueryClientOptions(setErrorMessage));

    const devConfigObj = useMemo(() => getDevConfig(setSuccessMessage, topOfPageRef, setErrorMessage), [setSuccessMessage, topOfPageRef, setErrorMessage]);
    const customTree = useMemo(() => buildRoutes(iuConfigObj, devConfigObj), [iuConfigObj, devConfigObj]);

    return (
        <StudentProfileProvider>
            <App
                topOfPageRef={topOfPageRef}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            >
                {customTree}
            </App>
        </StudentProfileProvider>
    );
};

export default BuildConfigLang;
