import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { clearValuesFromList, formatClassNames } from '../../../configBuilder/configUtil';
import { Label } from '@radix-ui/react-label';
import ValidationError from '../FormValidationError/FormValidationError';
import { useState } from 'react';
import { getLookupOptions, getExactLookup } from '../../../api/getLookupAutofill';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';
import debounce from 'lodash.debounce';
import './FormAutoComplete.scss';
import { AutoCompleteCategoryEnum } from './FormAddressBlock';

interface IAutoCompleteProps {
    iuLabel: string;
    yupValueKey: keyof StudentAnswersObj;
    iuCustomStyle?: ICustomStyle[];
    prefix?: string;
    category: AutoCompleteCategoryEnum;
    yupValueKeys: string[];
}

export interface IAutoCompleteOption {
    label: string;
    value: string;
    subtext?: string;
    __isNew__?: boolean;
}

export const FormAutoComplete = (props: IAutoCompleteProps) => {
    const { iuLabel, yupValueKey, iuCustomStyle, prefix = '', category, yupValueKeys } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const { yupError } = useGetAnswersValidity(yupValueKey);
    const [localState, setLocalState] = useState<IAutoCompleteOption>({ value: '', label: '', subtext: '' });

    const classNames = {
        BasicForm: 'BasicForm',
        BasicForm__question: 'BasicForm__question',
        FormAutoComplete__subtext: 'FormAutoComplete__subtext',
        FormAutoComplete__text: 'FormAutoComplete__text',
    };
    const handleChange = (event: IAutoCompleteOption | null) => {
        // user is clearing answer
        if (event === null) {
            setLocalState({ value: '', label: '' });
            setStudentAnswers(prevValue => clearValuesFromList(prevValue, yupValueKeys, prefix));
        }
        // user didn't find address in auto-suggestion
        else if (event.__isNew__) {
            setLocalState(event);
            // clears out any previous answers
            setStudentAnswers(prevValue => clearValuesFromList(prevValue, yupValueKeys, prefix));
            setStudentAnswers(prevValue => ({ ...prevValue, [yupValueKey]: event.label }));
        }
        // user found a suggestion and needs exact lookup
        else {
            getExactLookup(event, setStudentAnswers, setLocalState, prefix, category, yupValueKeys);
        }
    };

    const debouncedOptions = debounce(getLookupOptions, 200);
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.BasicForm}>
            <Label id={yupValueKey} className={newClassNames.BasicForm__question}>
                {iuLabel}
            </Label>
            <div>
                <AsyncCreatableSelect<IAutoCompleteOption, false>
                    aria-errormessage={`${yupValueKey}-error`}
                    aria-invalid={yupError.length > 0}
                    aria-labelledby={yupValueKey}
                    value={localState.label ? localState : { value: '', label: studentAnswers[yupValueKey]?.toString() || '' }}
                    placeholder="Search for your address..."
                    classNamePrefix="FormAutoComplete"
                    styles={{
                        menu: (baseStyles, { options }) => ({
                            ...baseStyles,
                            display: options.length > 0 ? baseStyles.display : 'none',
                        }),
                        menuList: (baseStyles, { options }) => ({
                            ...baseStyles,
                            display: options.length > 0 ? baseStyles.display : 'none',
                            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                        }),
                    }}
                    onChange={handleChange}
                    loadOptions={inputValue => debouncedOptions(inputValue, category)}
                    isClearable
                    backspaceRemovesValue
                    controlShouldRenderValue
                    onBlur={debouncedOptions.cancel}
                    formatCreateLabel={(inputValue: string) => (
                        <>
                            <strong>Use:</strong> {inputValue}
                        </>
                    )}
                    formatOptionLabel={(inputValue: IAutoCompleteOption) => (
                        <>
                            <div className={newClassNames.FormAutoComplete__text}>{inputValue.label}</div>
                            <div className={newClassNames.FormAutoComplete__subtext}>{inputValue.subtext}</div>
                        </>
                    )}
                />
                <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};
