import * as Select from '@radix-ui/react-select';
import React from 'react';
import { getSelectOptions, formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import ValidationError from '../FormValidationError/FormValidationError';
import './FormSelectGroup.scss';
import { Label } from '@radix-ui/react-label';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';

interface ISingleSelectProps {
    iuSideOffset?: number;
    yupValueKey: keyof StudentAnswersObj & string;
    setValues: (values: React.SetStateAction<StudentAnswersObj>) => void;
    value: StudentAnswersObj[keyof StudentAnswersObj];
    iuCustomStyle?: ICustomStyle[];
    dropdownOption?: string;
    'aria-labelledby'?: string;
}

const SingleSelectWithoutMemo = (props: ISingleSelectProps) => {
    const { iuSideOffset, yupValueKey, setValues, value, iuCustomStyle, dropdownOption = yupValueKey, ...otherProps } = props;
    const valueKey = yupValueKey.toString();
    const yupError = useGetAnswersValidity(yupValueKey);

    const options = getSelectOptions(dropdownOption);

    const classNames = {
        Select__selectTrigger: 'Select__selectTrigger',
        Select__triggerIcon: 'Select__triggerIcon',
        Select__triggerIconContainer: 'Select__triggerIconContainer',
        Select__optionsViewport: 'Select__optionsViewport',
        Select__item: 'Select__item',
        Select__iconContainer: 'Select__iconContainer',
        Select__checkIcon: 'Select__checkIcon',
        Select__content: 'Select__content',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div>
            <Select.Root
                onValueChange={(value: string) =>
                    setValues(prevValue => {
                        return { ...prevValue, [yupValueKey]: value };
                    })
                }
                name={valueKey}
                value={value?.toString()}
            >
                <Select.Trigger
                    className={newClassNames.Select__selectTrigger}
                    data-testid={valueKey}
                    aria-labelledby={otherProps['aria-labelledby'] ? otherProps['aria-labelledby'] : yupValueKey}
                >
                    <Select.Value placeholder="" />
                    <Select.Icon className={newClassNames.Select__triggerIconContainer}>
                        <span className={`material-symbols-outlined ${newClassNames.Select__triggerIcon}`}>expand_more</span>
                    </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                    <Select.Content
                        position="popper"
                        collisionPadding={{ top: 100, bottom: 100, right: 0, left: 0 }}
                        sideOffset={iuSideOffset}
                        style={{ maxHeight: 'var(--radix-select-content-available-height)', width: 'var(--radix-select-trigger-width)' }}
                    >
                        <Select.Viewport className={newClassNames.Select__optionsViewport}>
                            {[...options.entries()].map(option => {
                                const [key, value] = option;
                                return (
                                    <Select.Item key={`${valueKey}-${key}`} className={newClassNames.Select__item} value={key.toString()}>
                                        <Select.ItemText>{value}</Select.ItemText>
                                        <div className={newClassNames.Select__iconContainer}>
                                            <Select.ItemIndicator>
                                                <span className={`material-symbols-outlined ${newClassNames.Select__checkIcon}`}>done</span>
                                            </Select.ItemIndicator>
                                        </div>
                                    </Select.Item>
                                );
                            })}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
            <ValidationError yupError={yupError.yupError} yupValueKey={valueKey} />
        </div>
    );
};

export const SingleSelect = React.memo(SingleSelectWithoutMemo) as typeof SingleSelectWithoutMemo;

export interface IFormSelectSingleProps {
    iuLabel: string;
    yupValueKey: keyof StudentAnswersObj;
    dropdownOption?: string;
    iuSideOffset?: number;
    iuCustomStyle?: ICustomStyle[];
}
export const FormSelectSingle = (props: IFormSelectSingleProps) => {
    const { iuLabel, yupValueKey, iuCustomStyle, dropdownOption, iuSideOffset } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();

    const classNames = {
        SelectSingle: 'SelectSingle',
        SelectSingle__label: 'SelectSingle__label',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.SelectSingle}>
            <Label className={newClassNames.SelectSingle__label} id={yupValueKey.toString()}>
                {iuLabel}
            </Label>
            <SingleSelect
                yupValueKey={yupValueKey}
                setValues={setStudentAnswers}
                value={studentAnswers[yupValueKey]}
                iuCustomStyle={iuCustomStyle}
                key={yupValueKey.toString()}
                dropdownOption={dropdownOption}
                iuSideOffset={iuSideOffset}
            />
        </div>
    );
};
