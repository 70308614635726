import { Separator } from '@radix-ui/react-separator';
import { styled } from '@stitches/react';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { SeparatorOrientations, SeparatorWeights, SeparatorWeightVariants } from '../../../interfaces/VariationTypes';
import './BasicSeparator.scss';

// FIXME color should be themed variant, not css class when we do that story
interface ISeparatorProps {
    orientation: SeparatorOrientations;
    iuCustomStyle?: ICustomStyle[];
    weight?: SeparatorWeightVariants;
}

export const BasicSeparator = (props: ISeparatorProps) => {
    const { orientation, iuCustomStyle, weight = 'thin' } = props;
    const StyledSeparator = styled(Separator, {
        '&[data-orientation=horizontal]': { height: SeparatorWeights[weight], width: '100%' },
        '&[data-orientation=vertical]': { minHeight: '100%', width: SeparatorWeights[weight] },
    });

    const classNames = {
        BasicSeparator: 'BasicSeparator',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return <StyledSeparator orientation={orientation} className={newClassNames.BasicSeparator} />;
};
