import { string, boolean, date, object } from 'yup';
import { StudentAnswersObj } from '../StudentAnswersType';

const currentDate = new Date();
export const currentYear = currentDate.getFullYear();
export const earliestSupportedYear = currentYear - 81;
export const latestSupportedYear = currentYear - 13;
export const supportedMonth = currentDate.getMonth();
export const supportedDate = currentDate.getDate();

const isValidString = (value: string) => {
    return value && value.length > 0;
};

export const personalInformationSchema = {
    fname: string().required('First name is required.'),
    mname: string().optional(),
    lname: string().required('Last name is required.'),
    pname: string().optional(),
    gender: string().required('Gender is required.'),
    gender_identity: string().optional(),
    preferred_pronoun: string().optional(),
    email: string().optional(),
    phone_type: string().required('Phone Type is required.'),
    birth_day: date()
        .typeError('A valid date of birth is required.')
        .required('A valid date of birth is required.')
        .min(
            new Date(earliestSupportedYear, supportedMonth, supportedDate),
            `The minimum supported year is ${earliestSupportedYear}. Please contact help support for assistance.`
        )
        .max(new Date(latestSupportedYear, supportedMonth, supportedDate), `You must be ${currentYear - latestSupportedYear} years or older to apply.`),

    phone_no_cell_area: string()
        .matches(/^\(?([2-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/, 'Please provide a valid US phone number.')
        .when('cell_txt_opt_in', {
            is: true,
            then: schema => schema.required('A cell phone number is required to opt in to text message updates.'),
            otherwise: schema =>
                schema.when('phone_no_cell_area', {
                    is: (value: string) => !value,
                    then: schema => schema.required('Please enter a cell phone number.'),
                    otherwise: schema => schema.optional(),
                }),
        }),

    cell_txt_opt_in: boolean().optional(),
};

export const contactInfoSchema = {
    address: string().required('Address is required.'),
    apt_num: string().optional(),
    city: string().required('City is required.'),
    state: string().when('country', {
        is: (value: string) => value && ['USA', 'CAN'].includes(value.toUpperCase()),
        then: schema => schema.required('State is required.'),
        otherwise: schema => schema.optional(),
    }),

    zip: string().when('country', {
        is: (value: string) => value && ['USA'].includes(value.toUpperCase()),
        then: schema => schema.required('Zip/Postal Code is required').min(5, 'Zip/Postal Code must be at least 5 characters.'),
        otherwise: schema => schema.optional(),
    }),

    country: string().required('Country is required.'),
    mail_same_as_perm: string().optional(),
    mailing_address: string().optional(),
    mailing_apt_num: string().optional(),
    mailing_city: string().optional(),
    mailing_state: string().optional(),
    mailing_zip: string().optional(),
    mailing_country: string().optional(),
    mail_eff_date_day: string().optional(),
};

export const citizenshipSchema = {
    citizen_type: string().optional(),
    citizen_country: string().when('citizen_type', {
        is: (value: string) => isValidString(value) && value && value !== '7',
        then: schema => schema.required('Citizen Country is required.'),
        otherwise: schema => schema.optional(),
    }),

    citizen_visa_status: string().when('citizen_type', {
        is: (value: string) => isValidString(value) && value && value !== '7',
        then: schema => schema.required('What is your visa type?'),
        otherwise: schema => schema.optional(),
    }),
    english_radio: string().optional(),
    native_language: string().optional(),
};

export const ethnicitySchema = {
    ethnicity_hispanic: string().optional(),
    race_american_indian: boolean().optional(),
    race_asian: boolean().optional(),
    race_black: boolean().optional(),
    race_native_hawaiian: boolean().optional(),
    race_white: boolean().optional(),
};

export const militarySchema = {
    military_status: string().optional(),
    military_status_specified: string().optional(),
    military_branch: string().optional(),
};
export const yourPlansSchema = {
    app_major: string().required('This is a required question.'),
    app_major2: string().when('app_major', {
        is: (value: string) => isValidString(value) && value && value !== '2',
        then: schema => schema.required('This is a required question.'),
        otherwise: schema => schema.optional(),
    }),
    enroll_term: string().when('app_major', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('This is a required question.'),
        otherwise: schema => schema.optional(),
    }),
    msw_standing_schedule: string().optional(),
    bach_social_work: string().optional(),
    cacrep_masters: string().optional(),
    teaching_license: string().optional(),
    msw_accelerated_explain: string().optional(),
};

export const educationalSchema = {
    prev_attend: string().required('This is a required question.'),
    prev_attend_from_month: string().optional(),
    prev_attend_from_year: string().optional(),
    prev_attend_to_month: string().optional(),
    prev_attend_to_year: string().optional(),
    college_0_ceeb: string().optional(),
    college_0_name: string().required('This is a required question.'),
    college_0_addr1: string().optional(),
    college_0_city: string().optional(),
    college_0_state: string().optional(),
    college_0_zip: string().optional(),
    degree_earned_0: string().optional(),
    gpa_0: string().optional(),
    credits_earned_0: string().optional(),
    attended_from_month_0: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required.'),
        otherwise: schema => schema.optional(),
    }),

    attended_from_year_0: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required.'),
        otherwise: schema => schema.optional(),
    }),

    attended_to_month_0: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required.'),
        otherwise: schema => schema.optional(),
    }),

    attended_to_year_0: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required.'),
        otherwise: schema => schema.optional(),
    }),

    college_1_ceeb: string().optional(),
    college_1_name: string().optional(),
    college_1_addr1: string().optional(),
    college_1_city: string().optional(),
    college_1_state: string().optional(),
    college_1_zip: string().optional(),
    degree_earned_1: string().optional(),
    gpa_1: string().optional(),
    credits_earned_1: string().optional(),
    attended_from_month_1: string().optional(),
    attended_from_year_1: string().optional(),
    attended_to_month_1: string().optional(),
    attended_to_year_1: string().optional(),
    college_2_ceeb: string().optional(),
    college_2_name: string().optional(),
    college_2_addr1: string().optional(),
    college_2_city: string().optional(),
    college_2_state: string().optional(),
    college_2_zip: string().optional(),
    degree_earned_2: string().optional(),
    gpa_2: string().optional(),
    credits_earned_2: string().optional(),
    attended_from_month_2: string().optional(),
    attended_from_year_2: string().optional(),
    attended_to_month_2: string().optional(),
    attended_to_year_2: string().optional(),
    college_3_ceeb: string().optional(),
    college_3_name: string().optional(),
    college_3_addr1: string().optional(),
    college_3_city: string().optional(),
    college_3_state: string().optional(),
    college_3_zip: string().optional(),
    degree_earned_3: string().optional(),
    gpa_3: string().optional(),
    credits_earned_3: string().optional(),
    attended_from_month_3: string().optional(),
    attended_from_year_3: string().optional(),
    attended_to_month_3: string().optional(),
    attended_to_year_3: string().optional(),
    college_4_ceeb: string().optional(),
    college_4_name: string().optional(),
    college_4_addr1: string().optional(),
    college_4_city: string().optional(),
    college_4_state: string().optional(),
    college_4_zip: string().optional(),
    degree_earned_4: string().optional(),
    gpa_4: string().optional(),
    credits_earned_4: string().optional(),
    attended_from_month_4: string().optional(),
    attended_from_year_4: string().optional(),
    attended_to_month_4: string().optional(),
    attended_to_year_4: string().optional(),
};
export const courseworkSchema = {
    previous_grad_coursework: string().optional(),
    previous_grad_coursework_schools: string().optional(),
};

export const testScoresSchema = {
    toefl_taken: string().optional(),
    toefl_taken_month: string().optional(),
    toefl_taken_year: string().optional(),
    toefl_score: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Score.'),
        otherwise: schema => schema.optional(),
    }),
    toefl_plan_month: string().optional(),
    toefl_plan_year: string().optional(),
};

export const finalStepsSchema = {
    suspended: string().required('This is a required question.'),
    suspended_explain: string().when('suspended', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.required('Have you been suspended from any school? Please explain.'),
        otherwise: schema => schema.optional(),
    }),
    agree_counselor_request_waiver: string().required('This is a required question.'),
    legal_agree: boolean().required('You must accept these terms to submit your application.').oneOf([true], 'You must accept these terms to submit your application.'),
};

export const completeSchema = object().shape<Record<keyof StudentAnswersObj, any>>({
    ...personalInformationSchema,
    ...contactInfoSchema,
    ...citizenshipSchema,
    ...ethnicitySchema,
    ...militarySchema,
    ...yourPlansSchema,
    ...educationalSchema,
    ...testScoresSchema,
    ...courseworkSchema,
    ...finalStepsSchema,
});
