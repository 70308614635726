import React, { useState } from 'react';
import { formatClassNames, getYupValidationError } from '../../../configBuilder/configUtil';
import ValidationError from '../FormValidationError/FormValidationError';
import { Label } from '@radix-ui/react-label';
import FormStyledInput from '../FormStyledInput/FormStyledInput';
import { IFormComponentProps, primitives } from '../../../interfaces/StudentProfileTypes';
import { SupportedInputTypes, TextInputVariant } from '../../../interfaces/VariationTypes';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import { completeSchema } from '../../../tenantConfig/dataModel/yupSchemas';

interface IDatePickerProps<T extends SupportedComplexObjs> {
    yupValueKey: keyof T;
    iuLabel: string;
    setValues: (value: React.SetStateAction<T>) => void;
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
    value?: primitives;
    yupError: string[];
}

export const DatePicker = <T extends SupportedComplexObjs>(props: IDatePickerProps<T>) => {
    const { yupValueKey, iuLabel, setValues, iuCustomStyle, iuVariant, value, yupError } = props;
    const [userInput, setUserInput] = useState(value);
    const [invalidDobError, setInvalidDobError] = useState<string[]>([]);
    const valueKey = yupValueKey.toString();

    const dateOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
        const [year, month, day] = e.target.value.split('-').map(el => parseInt(el));
        const date = new Date(year, month - 1, day).toString();
        const validationError = (await getYupValidationError<T>(completeSchema, yupValueKey, { [yupValueKey]: date } as T)) ?? [];
        setInvalidDobError(validationError);

        // if valid, set value with user's input
        if (validationError.length === 0) {
            setValues(prevValue => ({ ...prevValue, [yupValueKey]: e.target.value }));
        } else if (value) {
            setValues(prevValue => ({ ...prevValue, [yupValueKey]: '' }));
        }
    };

    const classNames = {
        BasicForm: 'BasicForm',
        BasicForm__question: 'BasicForm__question',
        BasicForm__textAnswer: 'BasicForm__textAnswer',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.BasicForm}>
            <Label id={valueKey} className={newClassNames.BasicForm__question}>
                {iuLabel}
            </Label>
            <div>
                <FormStyledInput
                    className={newClassNames.BasicForm__textAnswer}
                    inputType={SupportedInputTypes.date}
                    aria-labelledby={valueKey}
                    onChange={dateOnChange}
                    value={userInput ? userInput : value}
                    iuVariant={iuVariant}
                    iuCustomStyle={iuCustomStyle}
                    aria-invalid={yupError.length > 0 || invalidDobError.length > 0}
                    aria-describedby={`${valueKey}-error`}
                />
                <ValidationError yupError={yupError.concat(invalidDobError)} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};

export const FormDatePicker = (props: IFormComponentProps) => {
    const { yupValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return <DatePicker {...otherProps} yupValueKey={yupValueKey} value={studentAnswers[yupValueKey]} {...validity} setValues={setStudentAnswers} />;
};
