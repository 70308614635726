import React, { useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from '../Components/BasicUtil/Loading/LoadingSpinner';
import BasicDialog from '../Components/UserFeedback/BasicDialog/BasicDialog';
import { IClientSnapshot, emptyClientSnapshot } from '../interfaces/StudentProfileTypes';
import { useGetStudentProfile } from '../api/getStudentProfile';
import { StudentAnswersObj, emptyStudentAnswersObj } from '../tenantConfig/StudentAnswersType';

const defaultContextSetter = () => {};
type StudentAnswersContextType = {
    studentAnswers: StudentAnswersObj;
    setStudentAnswers: React.Dispatch<React.SetStateAction<StudentAnswersObj>>;
};

export const StudentAnswersContext = React.createContext<StudentAnswersContextType>({
    studentAnswers: emptyStudentAnswersObj,
    setStudentAnswers: defaultContextSetter,
});

type ClientSnapshotContextType = {
    clientSnapshot: IClientSnapshot;
    setClientSnapshot: React.Dispatch<React.SetStateAction<IClientSnapshot>>;
};
export const ClientSnapshotContext = React.createContext<ClientSnapshotContextType>({
    clientSnapshot: emptyClientSnapshot,
    setClientSnapshot: defaultContextSetter,
});

interface IStudentProfileProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const StudentProfileProvider = (props: IStudentProfileProviderProps) => {
    const { children } = props;

    const [errorMessage, setErrorMessage] = useState('');
    const [studentAnswers, setStudentAnswers] = useState<StudentAnswersObj>(emptyStudentAnswersObj);
    const [clientSnapshot, setClientSnapshot] = useState<IClientSnapshot>(emptyClientSnapshot);

    const studentClientSnapshot = useMemo(() => ({ clientSnapshot, setClientSnapshot }), [clientSnapshot]);

    const { isLoading } = useGetStudentProfile(setStudentAnswers, setClientSnapshot, !!clientSnapshot.is_logged_in, setErrorMessage);

    // manage user session
    const tempIsUserLoggedIn = getIsUserLoggedIn();
    useEffect(() => {
        setClientSnapshot(prevValue => ({ ...prevValue, is_logged_in: tempIsUserLoggedIn }));
    }, [tempIsUserLoggedIn]);

    return (
        <ClientSnapshotContext.Provider value={studentClientSnapshot}>
            <StudentAnswersContext.Provider value={{ studentAnswers, setStudentAnswers }}>
                {isLoading ? <LoadingSpinner /> : undefined}
                {children}
                {errorMessage ? (
                    <BasicDialog handleClose={() => setErrorMessage('')}>
                        <>{errorMessage}</>
                    </BasicDialog>
                ) : undefined}
            </StudentAnswersContext.Provider>
        </ClientSnapshotContext.Provider>
    );
};

// NOTE: does not validate cookies in any way -- handled by auth systems
export const getIsUserLoggedIn = () => {
    const cognitoCookies = document.cookie.split('; ').filter(cookie => cookie.startsWith('CognitoIdentityServiceProvider'));
    return cognitoCookies.length > 0;
};
