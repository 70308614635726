import React, { JSXElementConstructor, ReactElement, useState } from 'react';
import { ObjectSchema } from 'yup';
import { IBooleanConditionObj, IConditionObj } from '../interfaces/ConditionTypes';
import { IConfigLangObj, IDevConfigLangObj, IRouteObj } from '../interfaces/ConfigLangTypes';
import { ICustomStyle } from '../interfaces/CustomStyleTypes';
import { SupportedComplexObjs } from '../interfaces/SharedTypes';
import { primitives } from '../interfaces/StudentProfileTypes';
import { StudentAnswersObj } from '../tenantConfig/StudentAnswersType';

export const evaluateConditionals = <T extends SupportedComplexObjs>(condition: IConditionObj<T>, values: T) => {
    const valueToCompare = values[condition.valueKey];
    return comparisonOperations[condition.operator](valueToCompare, condition.target);
};

export const evaluateBooleanCondition = (booleanCondition: IBooleanConditionObj) => {
    return !!booleanOperations[booleanCondition.operator](booleanCondition.val1, booleanCondition.val2);
};

export const buildRoutes = (configObj: IRouteObj[], devConfigObj: IDevConfigLangObj) => {
    return configObj.map(route => React.createElement(devConfigObj.Route.component, { ...route.props, element: reachChildren(route, devConfigObj) }));
};

export const reachChildren = (
    configObj: IConfigLangObj,
    devConfigObj: IDevConfigLangObj
): null | (ReactElement<JSX.Element, string | JSXElementConstructor<any>> | number | string)[] => {
    if (!configObj.children) return null;

    return configObj.children.map(child => {
        if (typeof child === 'string' || typeof child === 'number') return child;

        let processedProps;
        if (child.props && child.props?.renderProps) {
            processedProps = { ...child.props, renderProps: handleRenderProps(child.props.renderProps, devConfigObj) };
        } else {
            processedProps = child.props;
        }

        //if this is a custom MA supported component, add in necessary support, otherwise use the barebones.
        const devConfig = child.component in devConfigObj ? devConfigObj[child.component] : { component: child.component, props: processedProps };
        return React.createElement(devConfig.component, { ...processedProps, ...devConfig.props }, reachChildren(child, devConfigObj));
    });
};

const handleRenderProps = (renderProps: object, devConfigObj: IDevConfigLangObj) => {
    const renderedProps = Object.entries(renderProps).map(prop => {
        return [prop[0], reachChildren({ children: [prop[1]] } as IConfigLangObj, devConfigObj)];
    });
    return Object.fromEntries(renderedProps);
};

const comparisonOperations = {
    notEquals: <T extends SupportedComplexObjs>(value: T[keyof T], target: primitives) => value !== target,
    equals: <T extends SupportedComplexObjs>(value: T[keyof T] | null, target: primitives) => value === target,
    lt: (value: number, target: number) => value < target,
    lte: (value: number, target: number) => value <= target,
    gt: (value: number, target: number) => value > target,
    gte: (value: number, target: number) => value >= target,
    notAnswered: <T extends SupportedComplexObjs>(value: T[keyof T]) => !value,
    answered: <T extends SupportedComplexObjs>(value: T[keyof T]) => !!value,
    in: <T extends SupportedComplexObjs>(value: T[keyof T], target: primitives[]) => target.includes(value),
    notIn: <T extends SupportedComplexObjs>(value: T[keyof T], target: primitives[]) => !target.includes(value),
} as { [key: string]: <T extends SupportedComplexObjs>(value: T[keyof T], target: primitives | primitives[]) => boolean };

const booleanOperations = {
    or: (val1: primitives, val2: primitives) => val1 || val2,
    and: (val1: primitives, val2: primitives) => val1 && val2,
} as { [key: string]: (value: primitives, target: primitives) => boolean };

export const getSelectOptions = (valueKey: string): Map<string, string> => {
    const selectJson = require('../processedSelects.json');
    const selectOptions = selectJson[valueKey];
    if (!selectOptions) {
        console.warn({
            errorType: 'CONFIG WARNING',
            errorCode: 'MISSING SELECT OPTIONS',
            message: `No select options present for '${valueKey}' provide select options for '${valueKey}' in select option configuration.`,
        });
        return new Map();
    }
    return new Map(selectOptions);
};

export const getCustomDropin = (dropinName: string) => {
    const dropinJson = require('../processedDropins.json');
    const dropin = dropinJson[dropinName];
    if (!dropin) {
        console.warn({
            errorType: 'CONFIG WARNING',
            errorCode: 'MISSING DROPIN OPTION',
            message: `No dropin option present for '${dropinName}' provide dropin for '${dropinName}' in dropin option configuration.`,
        });
        return '';
    }
    return dropin;
};

export const formatClassNames = (classNames: { [key: string]: string }, iuCustomStyleList: ICustomStyle[] = []) => {
    const updatedClassNames = iuCustomStyleList.map(iuCustomStyle => {
        const originalValue = classNames[iuCustomStyle.className] || '';
        if (iuCustomStyle.operation === 'replace') {
            return [originalValue, originalValue.replace(iuCustomStyle.className, iuCustomStyle.target)];
        } else {
            return [originalValue, originalValue ? originalValue + ' ' + iuCustomStyle.target : ''];
        }
    });
    return { ...classNames, ...Object.fromEntries(updatedClassNames) };
};

export const getYupValidationError = async <T extends SupportedComplexObjs>(schema: ObjectSchema<any>, valueKey: keyof T, values: T): Promise<string[] | null> => {
    try {
        await schema.validateAt(valueKey.toString(), values, { abortEarly: false });
    } catch (err: any) {
        return err.errors;
    }
    return null;
};

export const getAllYupErrors = async <T extends SupportedComplexObjs>(schema: ObjectSchema<any>, values: T) => {
    const validationErrors = Object.create({});

    for (const valueKey in schema.describe().fields) {
        const error = await getYupValidationError(schema, valueKey, values);
        if (error) validationErrors[valueKey] = error;
    }
    return validationErrors;
};

export const formatAddressPrefix = (prefix: string, suffix: string) => {
    if (!prefix) return suffix as keyof StudentAnswersObj;
    return `${prefix}_${suffix}` as keyof StudentAnswersObj;
};

export const clearValuesFromList = (previousValue: StudentAnswersObj, valuesToEmpty: string[], prefix = '') => {
    const emptiedValues = Object.fromEntries(valuesToEmpty.map(key => [formatAddressPrefix(prefix, key), '']));
    return { ...previousValue, ...emptiedValues };
};

export const useClearOnHide = (props: {
    setStudentAnswers: React.Dispatch<React.SetStateAction<StudentAnswersObj>>;
    valueToWatch: primitives;
    answerKeysToClear: Array<keyof StudentAnswersObj>;
}) => {
    const { valueToWatch, setStudentAnswers, answerKeysToClear } = props;
    const [savedValueToWatch, setSavedValueToWatch] = useState<primitives>(valueToWatch);

    // initial load or first time the value gets set
    if (valueToWatch && !savedValueToWatch) {
        setSavedValueToWatch(valueToWatch);

        // when the value to watch changes
    } else if (valueToWatch !== savedValueToWatch) {
        setStudentAnswers(prevValue => clearValuesFromList(prevValue, answerKeysToClear));
        setSavedValueToWatch(valueToWatch);
    }
};
