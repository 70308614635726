import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { BasicSeparator } from '../BasicSeparator/BasicSeparator';
import './StyledSection.scss';

interface IStyledSectionProps {
    children: JSX.Element[] | JSX.Element;
    headerTitle: string;
    hasSeparator?: boolean;
    iuCustomStyle?: ICustomStyle[];
}

const StyledSection = (props: IStyledSectionProps) => {
    const { children, headerTitle, iuCustomStyle, hasSeparator } = props;

    const classNames = {
        StyledSection: 'StyledSection',
        StyledSection__formHeader: 'StyledSection__formHeader',
    };

    const newClassNames = formatClassNames(classNames, iuCustomStyle);
    return (
        <div className={newClassNames.StyledSection}>
            <h2 className={newClassNames.StyledSection__formHeader}>{headerTitle}</h2>
            {children}
            {hasSeparator ? <BasicSeparator weight="regular" orientation="horizontal" /> : undefined}
        </div>
    );
};

export default StyledSection;
