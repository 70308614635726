import { useQuery, useQueryClient } from 'react-query';
import { useClientSnapshot } from '../stateManagement/useClientSnapshot';
import { defaultReactQueryOptions } from './getCustomQueryClientOptions';
import { statusHandler } from './statusHandler';
import { useNavigate } from 'react-router-dom';
import { emptyClientSnapshot } from '../interfaces/StudentProfileTypes';
import { useStudentAnswers } from '../stateManagement/useStudentAnswers';
import { emptyStudentAnswersObj } from '../tenantConfig/StudentAnswersType';

export const useGetLogout = (performLogout: boolean, setPerformLogout: (performLogout: boolean) => void) => {
    const { setClientSnapshot } = useClientSnapshot();
    const { setStudentAnswers } = useStudentAnswers();
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const accountLogout = useQuery('account-logout', () => statusHandler(fetch, '/api/logout', 'GET'), {
        ...defaultReactQueryOptions,
        enabled: performLogout,
        onSuccess: () => {
            setClientSnapshot(emptyClientSnapshot);
            setStudentAnswers(emptyStudentAnswersObj);
            setPerformLogout(false);
            queryClient.clear();
            navigate('/login');
        },
    });
    return accountLogout;
};
