import React from 'react';
import ValidationError from '../FormValidationError/FormValidationError';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { Label } from '@radix-ui/react-label';
import { SingleCheckInput } from '../FormSingleCheckInput/FormSingleCheckInput';
import FormStyledInput from '../FormStyledInput/FormStyledInput';
import { SupportedInputTypes, TextInputVariant } from '../../../interfaces/VariationTypes';
import { IFormComponentProps, primitives } from '../../../interfaces/StudentProfileTypes';
import { SupportedComplexObjs } from '../../../interfaces/SharedTypes';
import { useStudentAnswers } from '../../../stateManagement/useStudentAnswers';
import { useGetAnswersValidity } from '../../../stateManagement/useYupValidationError';
import { StudentAnswersObj } from '../../../tenantConfig/StudentAnswersType';

interface IMaskedPhoneNumber<T extends SupportedComplexObjs> {
    yupValueKey: keyof T;
    iuLabel: string;
    value?: primitives;
    setValues: (value: React.SetStateAction<T>) => void;
    yupError: string[];
    iuCustomStyle?: ICustomStyle[];
    iuVariant?: TextInputVariant;
    'aria-describedby'?: string;
}

export const MaskedPhoneNumber = <T extends SupportedComplexObjs>(props: IMaskedPhoneNumber<T>) => {
    const { yupValueKey, iuLabel, setValues, yupError, iuCustomStyle, value, ...otherProps } = props;
    const valueKey = yupValueKey.toString();

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const matchValues = e.target.value.replace(/\D/g, '').match(/\(?(\d{0,3})\)?-?(\d{0,3})-?(\d{0,4})/);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, areaCode, leadingThree, lastFour] = matchValues ?? [];

        const maskedPhone = (areaCode ? `(${areaCode}` : '') + (leadingThree ? `)-${leadingThree}` : '') + (lastFour ? `-${lastFour}` : '');
        setValues(prevValue => ({ ...prevValue, [yupValueKey]: maskedPhone }));
    };

    const classNames = {
        BasicForm: 'BasicForm',
        BasicForm__question: 'BasicForm__question',
        BasicForm__inputError: 'BasicForm__inputError',
        BasicForm__textAnswer: 'BasicForm__textAnswer',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.BasicForm}>
            <Label className={newClassNames.BasicForm__question} id={valueKey}>
                {iuLabel}
            </Label>
            <div className={newClassNames.BasicForm__inputError}>
                <FormStyledInput
                    {...otherProps}
                    value={value}
                    aria-labelledby={valueKey}
                    inputType={SupportedInputTypes.text}
                    onChange={handlePhoneChange}
                    iuCustomStyle={iuCustomStyle}
                    aria-invalid={yupError.length > 0}
                    aria-describedby={props['aria-describedby'] ? props['aria-describedby'] : `${valueKey}-error`}
                />
                <ValidationError yupError={yupError} yupValueKey={yupValueKey} />
            </div>
        </div>
    );
};

interface IPhoneWithOptIn<T extends SupportedComplexObjs> extends IMaskedPhoneNumber<T> {
    optInValue?: boolean;
    iuOptInMessage: string;
    yupOptInValueKey: keyof T;
}

export const PhoneWithOptIn = <T extends SupportedComplexObjs>(props: IPhoneWithOptIn<T>) => {
    const { optInValue, value, yupValueKey, yupOptInValueKey, iuOptInMessage, iuCustomStyle, setValues, ...otherProps } = props;

    const classNames = {
        OptIn: 'OptIn',
        OptIn__container: 'OptIn__container',
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div className={newClassNames.OptIn}>
            <MaskedPhoneNumber
                {...otherProps}
                yupValueKey={yupValueKey}
                value={value}
                iuCustomStyle={iuCustomStyle}
                setValues={setValues}
                aria-describedby={`${yupOptInValueKey.toString()}-error ${yupValueKey.toString()}-error`}
            />
            <div className={newClassNames.OptIn__container}>
                <SingleCheckInput
                    iuLabel={iuOptInMessage}
                    setValues={setValues}
                    yupValueKey={yupOptInValueKey}
                    value={!!optInValue}
                    iuCustomStyle={iuCustomStyle}
                    yupError={[]}
                />
            </div>
        </div>
    );
};

export const FormMaskedPhoneNumber = (props: IFormComponentProps) => {
    const { yupValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return <MaskedPhoneNumber {...otherProps} {...validity} value={studentAnswers[yupValueKey]} yupValueKey={yupValueKey} setValues={setStudentAnswers} />;
};

interface IPhoneWithOptInProps extends IFormComponentProps {
    yupOptInValueKey: keyof StudentAnswersObj;
    iuOptInMessage: string;
}
export const FormPhoneWithOptIn = (props: IPhoneWithOptInProps) => {
    const { yupValueKey, yupOptInValueKey, ...otherProps } = props;

    const { studentAnswers, setStudentAnswers } = useStudentAnswers();
    const validity = useGetAnswersValidity(yupValueKey);

    return (
        <PhoneWithOptIn
            {...otherProps}
            value={studentAnswers[yupValueKey]}
            setValues={setStudentAnswers}
            optInValue={!!studentAnswers[yupOptInValueKey]}
            yupOptInValueKey={yupOptInValueKey}
            {...validity}
            yupValueKey={yupValueKey}
        />
    );
};
