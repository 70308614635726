import { IDevConfigLangObj } from '../interfaces/ConfigLangTypes';
import { Route, Navigate } from 'react-router-dom';
import { MutableRefObject } from 'react';
import AccountPopover from '../Components/Account/AccountPopover/AccountPopover';
import { LoginCard } from '../Components/Account/Login/LoginCard';
import { ForgotPasswordCard } from '../Components/Account/ResetPassword/ForgotPasswordCard';
import { ResetPasswordCard } from '../Components/Account/ResetPassword/ResetPasswordCard';
import { AccountCardWrapper } from '../Components/Account/SharedAccount';
import { ConfirmCard } from '../Components/Account/SignUp/ConfirmCard';
import { SignUpCard } from '../Components/Account/SignUp/SignUpCard';
import { FormAutoComplete } from '../Components/BasicForm/FormAutoComplete/FormAutoComplete';
import { FormDatePicker } from '../Components/BasicForm/FormDatePicker/FormDatePicker';
import { FormMaskedPhoneNumber, FormPhoneWithOptIn } from '../Components/BasicForm/FormMaskedPhoneNumber/FormMaskedPhoneNumber';
import { FormMaskedSsn } from '../Components/BasicForm/FormMaskedSsn/FormMaskedSsn';
import { FormRadioInput } from '../Components/BasicForm/FormRadioInput/FormRadioInput';
import { FormSingleCheckInput } from '../Components/BasicForm/FormSingleCheckInput/FormSingleCheckInput';
import { FormTextArea } from '../Components/BasicForm/FormTextArea/FormTextArea';
import { FormTextInput } from '../Components/BasicForm/FormTextInput/FormTextInput';
import AxsVisuallyHidden from '../Components/BasicUtil/AxsVisuallyHidden';
import { BasicAccordion, BasicAccordionItem } from '../Components/BasicUtil/BasicAccordion/BasicAccordion';
import { BasicSeparator } from '../Components/BasicUtil/BasicSeparator/BasicSeparator';
import { SaveButton, SubmitButton, NavButton } from '../Components/BasicUtil/Buttons/ActionButtons';
import ButtonWithIcon from '../Components/BasicUtil/Buttons/ButtonWithIcon';
import CustomDropin from '../Components/BasicUtil/Dropins/PartnerDropin';
import HideShowSection from '../Components/BasicUtil/HideShowSection/HideShowSection';
import { HorizontalNav, HorizontalNavMenuItem } from '../Components/BasicUtil/HorizontalNav/HorizontalNav';
import LoadingCircles from '../Components/BasicUtil/Loading/LoadingCircles';
import MaterialIcon from '../Components/BasicUtil/MaterialIcon';
import StyledSection from '../Components/BasicUtil/StyledSection/StyledSection';
import { ModalButton } from '../Components/UserFeedback/BasicDialog/BasicDialog';
import { FormAddressBlock } from '../Components/BasicForm/FormAutoComplete/FormAddressBlock';
import { FormSelectGroup } from '../Components/BasicForm/FormSelect/FormSelectGroup';
import { FormDependentSelect } from '../Components/BasicForm/FormSelect/FormDependentSelect';
import { FormSelectSingle } from '../Components/BasicForm/FormSelect/FormSelect';
import { FormCeebBlock } from '../Components/BasicForm/FormAutoComplete/FormCeebBlock';
import { StudentAnswerDropin } from '../Components/BasicUtil/Dropins/StudentAnswerDropin';
import { StudentAnswersConditional, ClientSnapshotConditional, NoContent } from '../Components/BasicUtil/ConditionalDisplay/ConditionalDisplay';
import { ResponsiveLayout } from '../Components/Layout/MobileResponsive/MobileResponsive';

export const getDevConfig = (
    setSuccessMessage: (successMessage: string) => void,
    topOfPageRef: MutableRefObject<HTMLDivElement | null>,
    setErrorMessage: (errorMessage: string) => void
): IDevConfigLangObj => {
    return {
        FormAddressBlock: {
            component: FormAddressBlock,
        },
        FormCeebBlock: {
            component: FormCeebBlock,
        },
        ResponsiveLayout: {
            component: ResponsiveLayout,
        },
        FormAutoComplete: {
            component: FormAutoComplete,
        },
        SaveButton: {
            component: SaveButton,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        LoadingCircles: {
            component: LoadingCircles,
        },
        BasicSeparator: {
            component: BasicSeparator,
        },
        SaveAndNavButton: {
            component: SaveButton,
            props: {
                setSuccessMessage: setSuccessMessage,
                topOfPageRef: topOfPageRef,
            },
        },
        FormDependentSelect: {
            component: FormDependentSelect,
        },
        SubmitButton: {
            component: SubmitButton,
            props: {
                setSuccessMessage: setSuccessMessage,
                topOfPageRef: topOfPageRef,
                setErrorMessage: setErrorMessage,
            },
        },
        NavButton: {
            component: NavButton,
            props: {
                topOfPageRef: topOfPageRef,
            },
        },
        ModalButton: {
            component: ModalButton,
        },
        Route: {
            component: Route as unknown as (props: any) => JSX.Element,
        },
        Navigate: {
            component: Navigate as unknown as (props: any) => JSX.Element,
        },
        MaterialIcon: {
            component: MaterialIcon,
        },
        BasicAccordion: {
            component: BasicAccordion,
        },
        BasicAccordionItem: {
            component: BasicAccordionItem,
        },
        AxsVisuallyHidden: {
            component: AxsVisuallyHidden,
        },
        FormTextInput: {
            component: FormTextInput,
        },
        SignUpCard: {
            component: SignUpCard,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        ConfirmCard: {
            component: ConfirmCard,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        FormRadioInput: {
            component: FormRadioInput,
        },
        FormSingleCheckInput: {
            component: FormSingleCheckInput,
        },
        FormMaskedPhoneNumber: {
            component: FormMaskedPhoneNumber,
        },
        FormPhoneWithOptIn: {
            component: FormPhoneWithOptIn,
        },
        StyledSection: {
            component: StyledSection,
        },
        FormDatePicker: {
            component: FormDatePicker,
        },
        FormMaskedSsn: {
            component: FormMaskedSsn,
        },
        HideShowSection: {
            component: HideShowSection as unknown as (props: any) => JSX.Element,
        },
        FormSelectGroup: {
            component: FormSelectGroup,
            props: {},
        },
        FormSelectSingle: {
            component: FormSelectSingle,
        },
        HorizontalNav: {
            component: HorizontalNav,
        },
        ButtonWithIcon: {
            component: ButtonWithIcon,
        },
        AccountPopover: {
            component: AccountPopover,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        CustomDropin: {
            component: CustomDropin,
        },
        HorizontalNavMenuItem: {
            component: HorizontalNavMenuItem,
        },
        FormTextArea: {
            component: FormTextArea,
        },
        AccountCardWrapper: {
            component: AccountCardWrapper,
        },
        LoginCard: {
            component: LoginCard,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        ResetPasswordCard: {
            component: ResetPasswordCard,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        ForgotPasswordCard: {
            component: ForgotPasswordCard,
            props: {
                setSuccessMessage: setSuccessMessage,
            },
        },
        NoContent: {
            component: NoContent,
        },
        StudentAnswersConditional: {
            component: StudentAnswersConditional,
        },
        ClientSnapshotConditional: {
            component: ClientSnapshotConditional,
        },
        StudentAnswerDropin: {
            component: StudentAnswerDropin,
        },
    };
};
