import { keyframes } from '@stitches/react';
import { formatClassNames } from '../../../configBuilder/configUtil';
import { ICustomStyle } from '../../../interfaces/CustomStyleTypes';
import { LoadingSizes, ThemeVariant } from '../../../interfaces/VariationTypes';
import './LoadingCircles.scss';

const opacityPulse = keyframes({
    '0%': { opacity: 1 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
});

const animationDelays = ['-0.6s', '-0.4s', '-0.2s'];

interface ILoadingCircleProps {
    iuVariant: keyof typeof ThemeVariant;
    size: keyof typeof LoadingSizes;
    iteration: number;
    iuCustomStyle?: ICustomStyle[];
}
const LoadingCircle = (props: ILoadingCircleProps) => {
    const { iuVariant, size, iteration, iuCustomStyle } = props;

    const classNames = {
        [`LoadingCircles__${iuVariant}`]: `LoadingCircles__${iuVariant}`,
    };
    const newClassNames = formatClassNames(classNames, iuCustomStyle);

    return (
        <div
            style={{
                animation: `${opacityPulse} 1s cubic-bezier(0.5, 0, 0.5, 1) ${animationDelays[iteration]} infinite`,
                height: LoadingSizes[size],
                width: LoadingSizes[size],
                borderRadius: '100%',
            }}
            className={newClassNames[`LoadingCircles__${iuVariant}`]}
        />
    );
};

interface ILoadingCirclesProps {
    iuVariant: keyof typeof ThemeVariant;
    size: keyof typeof LoadingSizes;
    iuCustomStyle?: ICustomStyle[];
}
const LoadingCircles = (props: ILoadingCirclesProps) => {
    return (
        <div
            style={{
                display: 'grid',
                alignContent: 'center',
                gridTemplateColumns: 'repeat(4, 1fr)',
                height: '100%',
                gap: `calc(${LoadingSizes[props.size]} / 2)`,
            }}
            aria-busy="true"
            aria-live="polite"
            aria-label="loading"
        >
            <LoadingCircle iteration={0} {...props} />
            <LoadingCircle iteration={1} {...props} />
            <LoadingCircle iteration={2} {...props} />
        </div>
    );
};

export default LoadingCircles;
